import React from "react";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";

import { Home } from "./Home";
import { HomeHeadline } from "./HomeHeadline";
import { useHomeData } from "./useHomeData";

export function HomeContainer() {
  const { data, error, loaded } = useHomeData();
  return (
    <PageContainer
      loading={!loaded}
      error={Boolean(error)}
      headline={<HomeHeadline data={data} />}
    >
      {<Home data={data} />}
    </PageContainer>
  );
}
