import React, { useCallback } from "react";
import { Pagination, Typography } from "@mui/material";

import { Geo, SportType } from "../../typings";
import { useCoachListData } from "./useCoachListData";
import { PageContainer } from "../../common/components/PageContainer/PageContainer";

import { CoachList } from "./CoachList";
import { CoachFilters } from "./CoachFilters";
import { CoachListEmpty } from "./CoachListEmpty";
import { useTitle } from "react-use";

export function CoachListContainer() {
  useTitle("Find your coach");

  const {
    data: { coaches, sportTypes },
    loaded,
    pagination,
    error,
    setSportTypeId,
    setGeo,
    setPage,
  } = useCoachListData();

  const handleSportTypeChange = useCallback((sportType?: SportType) => {
    setSportTypeId(sportType?.id);
  }, []);

  const handleGeoChange = useCallback((geo: Geo | null) => {
    setGeo(geo);
  }, []);

  return (
    <PageContainer loading={!loaded} error={Boolean(error)}>
      <Typography gutterBottom variant="h5" component="h1">
        Find your coach
      </Typography>
      <Typography gutterBottom variant="caption" component="p">
        Find a coach in every corner of the world
      </Typography>

      {loaded && (
        <>
          <CoachFilters
            sportTypes={sportTypes}
            onSportTypeChange={handleSportTypeChange}
            onGeoChange={handleGeoChange}
          />
          {coaches && coaches?.length > 0 ? (
            <CoachList coaches={coaches} />
          ) : (
            <CoachListEmpty />
          )}
          {Number(pagination?.pageCount) > 1 && (
            <Pagination
              sx={{ py: 6 }}
              count={pagination?.pageCount}
              page={pagination?.page}
              onChange={(_, page) => setPage(page)}
              color="primary"
            />
          )}
        </>
      )}
    </PageContainer>
  );
}
