import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

import {
  CoachRegistrationFormFields,
  CoachRegistrationFormData,
} from "../../typings";
import { Agreement } from "../../common/components/Agreement/Agreement";

const registrationFormId = "registration-form";

const defaultFormValues = {
  [CoachRegistrationFormFields.FullName]: "",
  [CoachRegistrationFormFields.Email]: "",
  [CoachRegistrationFormFields.Phone]: "",
};

type Props = {
  onRegister: (data: CoachRegistrationFormData) => void;
};

export const CoachRegistrationForm = ({ onRegister }: Props) => {
  const { handleSubmit, control } = useForm<CoachRegistrationFormData>({
    defaultValues: defaultFormValues,
  });
  const onSubmit: SubmitHandler<CoachRegistrationFormData> = (data) =>
    onRegister(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={registrationFormId}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Register as a coach
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={CoachRegistrationFormFields.FullName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <TextField label="Full name" required {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={CoachRegistrationFormFields.Email}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <TextField label="Email" required {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={CoachRegistrationFormFields.Phone}
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <MuiTelInput
                  {...field}
                  label="Phone number"
                  defaultCountry="GB"
                />
                <FormHelperText>
                  Please provide your mobile number, it will help trainees to
                  connect with you quickly
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Agreement />
        </Grid>
        <Grid item xs={12}>
          <Button sx={{ p: 2 }} variant="contained" type="submit">
            Register
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
