import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import {
  Controller,
  useFieldArray,
  ControllerRenderProps,
  FieldValues,
} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccountFileUpload } from "./AccountFileUpload";

type Props = {
  control: any;
  title: string;
  name: string;
  watch: any;
};

export const AccountCoachPrice = ({ control, name, title, watch }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, `${string}.${number}.price`>,
  ) => {
    const value = event.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      field.onChange(value);
    }
  };

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<FieldValues, `${string}.${number}.duration`>,
  ) => {
    const value = event.target.value;
    const regex = /^\d+$/;
    if (regex.test(value) || value === "") {
      field.onChange(value);
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <FormLabel sx={{ fontSize: "1.2rem" }}>{title}</FormLabel>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            p: 2,
            my: 2,
            border: 1,
            borderRadius: 1,
            borderColor: "grey.400",
          }}
        >
          <Controller
            control={control}
            name={`${name}.${index}.title`}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Title"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText="Please provide a short name for the training session."
              />
            )}
          />
          <Controller
            control={control}
            name={`${name}.${index}.isProgram`}
            render={({ field }) => (
              <FormControlLabel
                sx={{ my: 2 }}
                control={<Switch {...field} checked={field.value} />}
                label="I propose a program consisting of multiple sessions that should be paid as a program, not separated sessions"
                onChange={(e: any) => field.onChange(e.target.checked)}
              />
            )}
          />

          <Controller
            control={control}
            name={`${name}.${index}.price`}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(event) => handlePriceChange(event, field)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                size="small"
                label="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText="Please enter your price in euros so that trainees can see it on your profile. Please ensure that if you pay taxes, the price is provided including them."
              />
            )}
          />
          {!watch(`${name}.${index}.isProgram`) && (
            <Controller
              control={control}
              name={`${name}.${index}.duration`}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">min</InputAdornment>
                    ),
                  }}
                  onChange={(event) => handleDurationChange(event, field)}
                  size="small"
                  label="Duration"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText="Enter the duration of the training session in minutes. Please specify the total time that the session will take, including any warm-up or cool-down periods."
                />
              )}
            />
          )}
          {watch(`${name}.${index}.isProgram`) && (
            <Controller
              control={control}
              name={`${name}.${index}.program`}
              render={({ field }) => (
                <AccountFileUpload
                  name={`${name}.${index}.program`}
                  onChange={(file) => field.onChange(file)}
                  initialValue={field.value}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name={`${name}.${index}.description`}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                multiline
                variant="outlined"
                fullWidth
                rows={3}
                margin="normal"
              />
            )}
          />
          <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button
              type="button"
              variant="contained"
              size="large"
              startIcon={<DeleteIcon />}
              onClick={() => remove(index)}
            >
              Remove Option
            </Button>
          </Box>
        </Box>
      ))}

      <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
        <Button
          type="button"
          variant="contained"
          size="large"
          startIcon={<AddIcon />}
          onClick={() =>
            append({
              title: "",
              price: 0,
              description: "",
              duration: 0,
              isProgram: false,
            })
          }
        >
          Add Training Option
        </Button>
      </Box>
    </Box>
  );
};
