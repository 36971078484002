import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar, Box, FormControl, Grid, Typography } from "@mui/material";
import { Media } from "../../../typings";
import { UPLOAD_URL } from "../../../common/constants/config";
import defaultAvatar from "../../../common/images/default-avatar.svg";

interface AvatarProps {
  initialImage?: Media;
  onChange?: (file: File | null) => void;
}

export const AccountAvatar: React.FC<AvatarProps> = ({
  initialImage = null,
  onChange,
}) => {
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    onChange && onChange(file);
  }, [file, onChange]);

  const onDrop = (acceptedFile: File[]) => {
    setFile(acceptedFile[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    maxSize: 5000000, // 5 megabytes
  });

  const uploadedAvatarUrl = file ? URL.createObjectURL(file) : null;
  const avatarUrl = initialImage?.url
    ? `${UPLOAD_URL}${initialImage.url}`
    : defaultAvatar;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Avatar
          src={uploadedAvatarUrl || avatarUrl}
          sx={{ width: 150, height: 150 }}
          alt="User Avatar"
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <FormControl fullWidth margin="normal">
          <Box
            {...getRootProps()}
            sx={{
              border: 1,
              borderRadius: 1,
              borderColor: "grey.400",
              p: 4,
              cursor: "pointer",
              textAlign: "center",
              "&:hover": {
                borderColor: "#131313",
              },
            }}
          >
            <input {...getInputProps()} />
            <Typography
              variant="button"
              component="div"
            >{`Drag 'n' drop or click to select a photo`}</Typography>
            <Typography variant="caption">
              Only JPEG and PNG images up to 5MB
            </Typography>
          </Box>
        </FormControl>
      </Grid>
    </Grid>
  );
};
