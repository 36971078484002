import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Avatar,
  Box,
} from "@mui/material";

import { NavItem } from "./Header";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  menuItems: NavItem[];
  anchorEl: null | HTMLElement;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  onSignOut: () => void;
  username?: string;
}

const getFirstLetter = (username: string) => username[0].toUpperCase();

export const HeaderUserMenu = ({
  menuItems,
  anchorEl,
  onOpen,
  onClose,
  onSignOut,
  username,
}: Props) => {
  const handleLogout = () => {
    onClose();
    onSignOut();
  };

  const avatarName = username ? getFirstLetter(username) : "U";

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open user menu">
        <IconButton onClick={onOpen} sx={{ p: 0 }}>
          <Avatar sx={{ bgcolor: "primary.main" }}>{avatarName}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {menuItems.map(({ to, text }) => (
          <MenuItem key={to} onClick={onClose} component={RouterLink} to={to}>
            <Typography textAlign="center">{text}</Typography>
          </MenuItem>
        ))}

        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
