import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import trainee1 from "./images/trainee-1.png";
import trainee2 from "./images/trainee-2.png";
import trainee3 from "./images/trainee-3.png";
import founder2 from "./images/founder-2.jpg";

const missions = [
  "Facilitate access to expert coaching across diverse sports disciplines.",
  "Enable personalized training experiences through innovative technology.",
  "Promote safe and insured sporting practices.",
  "Encourage sports enthusiasts to explore new disciplines and locations.",
];
const ourTrainees = [
  {
    image: trainee1,
    text: (
      <>
        &quot;I love how BRO platform truly embodies <strong>adventure</strong>! It inspires me to explore new sports, exciting locations, and take on challenges. It’s all about embracing the spirit of adventure in sports!&quot;
      </>
    ),
    name: "Rachel Green",
    locality: "Bremen, Germany",
  },
  {
    image: trainee2,
    text: (
      <>
        &quot;I appreciate the <strong>inclusivity</strong> of BRO. It embraces all sports disciplines and skill levels, making sports accessible to everyone, regardless of their background or experience!&quot;
      </>
    ),
    name: "Alexander Lee",
    locality: "Barcelona, Spain",
  },
  {
    image: trainee3,
    text: (
      <>
        &quot;I&apos;m really impressed with how BRO prioritizes <strong>safety</strong>. Promoting safe training practices and providing insurance recommendations shows they genuinely care about the well-being of the trainees.&quot;
      </>
    ),
    name: "Julia Rodrigues",
    locality: "Cardiff, UK",
  },
];

const ourFounders = [
  {
    image: founder2,
    name: "Julia Sitnikova",
    text: "We created BRO to solve the challenge of finding reliable coaches for skiing, surfing, and gliding. My own frustrations, shared by many friends, highlighted the need for a trustworthy platform that prioritizes safety in training. Now BRO is a sport dating app that connects coaches with trainees, we also aim to match players with one another because understand importance of not just learning but practicing. Focusing on active outdoor sports, we prioritize safety by validating coaches and recommending sports insurance for all.",
    position: "Founder and CEO",
  },
];

type AboutFounderProps = {
  image: string;
  name: string;
  position: string;
  text: string;
};

function AboutFounder({ text, name, image, position }: AboutFounderProps) {
  return (
    <Grid item>
      <Card
        sx={{
          borderRadius: 8,
          backgroundColor: "secondary.main",
          boxShadow: "none",
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  width: "100%",
                  minHeight: { xs: 300, sm: 400 },
                  backgroundSize: { xs: "cover", sm: "cover" },
                  backgroundRepeat: "no-repeat",
                  borderRadius: 2,
                  backgroundImage: `url(${image})`,
                  backgroundPosition: { xs: "0 70%", sm: "0 0px" },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ fontSize: "1.2rem", mb: 2, textAlign: 'center' }}
                gutterBottom
              >
                “{text}”
              </Typography>
              <Box>
                <Typography variant="body1" component="h5" textAlign='center' >
                  {position}
                </Typography>
                <Typography variant="body1" component="h5" textAlign='center'>
                  {name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

type AboutTraineeFeedbackProps = {
  text: string | React.ReactNode;
  name: string;
  locality: string;
  image: string;
};

function AboutTraineeFeedback({
  text,
  name,
  locality,
  image,
}: AboutTraineeFeedbackProps) {
  return (
    <Grid item spacing={3} xs={12} md={4} sx={{ mt: 8 }}>
      <Card
        sx={{
          textAlign: "center",
          borderRadius: 8,
          backgroundColor: "secondary.main",
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 2,
            overflow: "visible",
          }}
        >
          <Avatar
            alt={name}
            src={image}
            sx={{
              width: 100,
              height: 100,
              marginBottom: 1,
              marginTop: -8,
            }}
          />
        </Box>
        <CardContent sx={{ minHeight: { md: 300 } }}>
          <Typography variant="body1">{name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {locality}
          </Typography>
          <Typography variant="body2" sx={{ pt: 2, fontSize: "1.2rem" }}>
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

function AboutBlock({ children }: { children: React.ReactNode }) {
  return (
    <Grid container spacing={3} sx={{ pb: 12 }}>
      {children}
    </Grid>
  );
}

function AboutTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      component="h1"
      variant="h5"
      sx={{
        fontWeight: 600,
        pb: 4,
        textAlign: "center",
        fontSize: { xs: "1.5rem", md: "2rem" },
      }}
    >
      {children}
    </Typography>
  );
}

export function About() {
  return (
    <>
      <AboutTitle>BRO VISION</AboutTitle>
      <AboutBlock>
        <Grid item>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.4rem", textAlign: 'center' }}
          >
            To create a global community where sports enthusiasts of all levels
            can connect, learn, and excel in their chosen disciplines, breaking
            down geographical barriers and fostering a culture of continuous
            improvement and adventure in sports
          </Typography>
        </Grid>
      </AboutBlock>

      <AboutTitle>BRO MISSION</AboutTitle>

      <AboutBlock>
        {missions.map((text, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <Paper
              sx={{
                p: 2,
                minHeight: { sm: 200 },
                borderRadius: 8,
                backgroundColor: "secondary.main",
                boxShadow: "none",
              }}
            >
              <Avatar
                sx={{ bgcolor: "common.white", color: "primary.main", mb: 2 }}
              >
                {index + 1}
              </Avatar>
              <Typography variant="body1" sx={{ fontSize: "1.4rem" }} >
                {text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </AboutBlock>

      <AboutTitle>BRO VALUES</AboutTitle>
      <AboutBlock>
        {ourTrainees.map(({ image, text, name, locality }) => (
          <AboutTraineeFeedback
            key={name}
            image={image}
            text={text}
            name={name}
            locality={locality}
          />
        ))}
      </AboutBlock>

      <AboutTitle>BRO STORY</AboutTitle>
      <Box>
        <AboutBlock>
          {ourFounders.map(({ image, name, position, text }) => (
            <AboutFounder
              key={name}
              image={image}
              text={text}
              name={name}
              position={position}
            />
          ))}
        </AboutBlock>
      </Box>
    </>
  );
}
