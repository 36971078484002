import React from "react";
import { Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ROUTS } from "../../common/constants/config";
import { Logo } from "../../common/components/Logo/Logo";

export function NotFound() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate(ROUTS.HOME); // Redirect to the home page
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        mt: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Logo />
      <Typography variant="h4" component="h1" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="subtitle1">
        {"Oops! It seems we can't find the page you're looking for."}
      </Typography>
      <Button variant="contained" sx={{ mt: 3 }} onClick={handleGoHome}>
        Go Back to Home
      </Button>
    </Container>
  );
}
