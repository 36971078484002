import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useToggle } from "react-use";

type Props = {
  children: React.ReactNode;
  title: string;
};
export const AccountCoachAccordion = ({ children, title }: Props) => {
  const [panel, togglePanel] = useToggle(true);

  return (
    <Accordion
      expanded={panel}
      onChange={togglePanel}
      sx={{
        backgroundColor: "secondary.main",
        boxShadow: "none",
        my: 2,
        px: { sm: 2 },
        py: 2,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
