import React from "react";
import { Link } from "@mui/material";
import { Logo } from "../Logo/Logo";
import { SITE_URL } from "../../constants/config";

type Props = {
  type: "desktop" | "mobile";
};

const mobileStyles = {
  display: { xs: "flex", md: "none" },
  flexGrow: 1,
};

const desktopStyles = { display: { xs: "none", md: "flex" } };

export const HeaderLogo = ({ type }: Props) => {
  return (
    <Link
      color="primary"
      href={SITE_URL}
      sx={type === "desktop" ? desktopStyles : mobileStyles}
    >
      <Logo
        sx={{
          maxWidth: { xs: 70, sm: 80 },
          maxHeight: { xs: 50, sm: 80 },
        }}
      />
    </Link>
  );
};
