export type Id = string | number;

// Response
export type ResponseError = any;

export enum ResponseDataFields {
  Meta = "meta",
  Data = "data",
  Attributes = "attributes",
}

export type ResponseMeta = {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
};

export type ResponseCommonDbData = {
  createdAt?: string;
  publishedAt?: string;
  updatedAt?: string;
};

export type ResponseData<TAttributes = any> = {
  attributes: TAttributes & ResponseCommonDbData;
  id: Id;
};

export type Response = {
  data: any;
  meta: ResponseMeta;
};

export type ResponseMediaDetails = {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  path: string | null;
  width: number;
  height: number;
  size: number;
  url: string;
};

export type ResponseMedia = {
  id: Id;
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  url: string;
  formats: {
    thumbnail: ResponseMediaDetails;
    medium: ResponseMediaDetails;
    small: ResponseMediaDetails;
    large: ResponseMediaDetails;
  };
};

// Request
export type RequestMethod =
  | "GET"
  | "DELETE"
  | "HEAD"
  | "OPTIONS"
  | "POST"
  | "PUT"
  | "PATCH"
  | "PURGE"
  | "LINK"
  | "UNLINK";

export type RequestParams<TData = any, TParams = any> = {
  params: TParams;
  method: RequestMethod;
  url: string;
  dataParser?: (data: any) => any;
  data?: TData;
};

export type Language = ResponseCommonDbData & {
  id: Id;
  name: string;
  code: string;
};

export type SportType = ResponseCommonDbData & {
  id: Id;
  name: string;
};

export type CoachProfile = {
  id: Id;
  fullName: string;
  phone: string;
  email: string;
  publishedAt: string;
  experience?: number;
  children?: boolean;
  adults?: boolean;
  about?: string;
  geoLocality?: string;
  geoCountry?: string;
  onlineSessions?: boolean;
  offlineSessions?: boolean;
  gallery?: Media[];
  sportType?: SportType;
  languages?: Language[];
  avatar?: Media;
  certificates?: string;
  tempSportType?: string;
  priceListOnline?: PriceListItem[];
  priceListInPerson?: PriceListItem[];
};

export type Coach = ResponseCommonDbData & {
  id: Id;
  about: string;
  adults: boolean;
  certificates: string;
  children: boolean;
  email: string;
  experience: number;
  fullName: string;
  phone: string;
  avatar: ResponseMedia;
  gallery: ResponseMedia[];
  languages: Language[];
  sportType: SportType;
  geoLocality: string;
  geoCountry: string;
  onlineSessions: boolean;
  offlineSessions: boolean;
  priceListOnline?: PriceListItem[];
  priceListInPerson?: PriceListItem[];
};

export type HomeData = ResponseCommonDbData & {
  id: Id;
  image: ResponseMedia;
  title: string;
  subtitle: string;
  details: {
    id: Id;
    image: ResponseMedia;
    title: string;
    items: {
      id: Id;
      image: ResponseMedia;
      text: string;
      title: string;
    }[];
  };
  service: {
    id: Id;
    title: string;
    items: {
      id: Id;
      title: string;
      text: string;
    }[];
  };
  coach: {
    id: Id;
    title: string;
    text: string;
    image: ResponseMedia;
  };

  coaches: Coach[];
};

export type StaticData = {
  title: string;
  content: string;
  seo: {
    title: string;
    description: string;
  };
};

// Forms
export enum BookFormFields {
  FullName = "fullName",
  Email = "email",
  PhoneNumber = "phoneNumber",
  WeekDays = "weekDays",
  Time = "time",
  Age = "age",
}

export type BookFormData = { [key in BookFormFields]?: string } & {
  [BookFormFields.WeekDays]: string[];
  [BookFormFields.Time]: string[];
};

export enum CoachRegistrationFormFields {
  FullName = "fullName",
  Email = "email",
  Phone = "phone",
  SportType = "sportType",
  Experience = "experience",
  SingleSessionPrice = "singleSessionPrice",
  FourSessionsPrice = "fourSessionsPrice",
  EightSessionsPrice = "eightSessionsPrice",
  Adults = "Adults",
  Children = "Children",
  Address = "Address",
  Country = "Country",
  City = "City",
  Language = "Language",
  About = "About",
  Certificates = "Certificates",
}

export type CoachRegistrationFormData = {
  [key in CoachRegistrationFormFields]?: string;
};

export type Geo = { country?: string; locality: string };

export type PriceListItem = {
  id?: Id;
  price: number;
  title: string;
  description: string;
  duration: number;
  isProgram?: boolean;
  program?: Media;
};

export type Media = {
  id: number;
  alternativeText: string | null;
  caption: string | null;
  width: number | null;
  height: number | null;
  formats?: {
    thumbnail: MediaFormat;
    small: MediaFormat;
    medium: MediaFormat | null;
    large: MediaFormat | null;
  };
  url: string;
};

export type MediaFormat = {
  width: number;
  height: number;
  size: number;
  url: string;
};

export interface AuthUser {
  id: number;
  fullName: string;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  phoneNumber: string;
  userRole: string;
  coach: CoachProfile | null;
}

export interface AuthResponse {
  jwt: string;
  user: AuthUser;
}

export type Option = {
  label: string;
  value: string;
};

export type Order = {
  id: Id;
  createdAt: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  weekDays: string;
  time: string;
  age: string;
  training: string;
  trainingPrice: number;
  isOnline: boolean;
  duration: number;
  description: string;
  zoomLink: string;
};
