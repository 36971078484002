import React from "react";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  url: string;
  name: string;
  onRemove: (name: string) => void;
}

export const AccountGalleryImage: React.FC<Props> = ({
  url,
  name,
  onRemove,
}) => (
  <ImageListItem key={name}>
    <img src={url} alt="Photo" />
    <ImageListItemBar
      sx={{
        background:
          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
      }}
      position="top"
      actionIcon={
        <IconButton
          sx={{ color: "white" }}
          aria-label="Delete photo"
          onClick={() => onRemove(name)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      actionPosition="right"
    />
  </ImageListItem>
);
