import { ResponseDataFields } from "../../typings";

type ResponseRawData = any;
type ResponseData = any;

const FieldsToFlat: string[] = [
  ResponseDataFields.Data,
  ResponseDataFields.Attributes,
];
const FieldsToSkip: string[] = [ResponseDataFields.Meta];

const flatData = (
  rawData: ResponseRawData,
  parsedData: ResponseRawData,
): ResponseData => {
  if (Array.isArray(rawData)) {
    return rawData.map(responseDataParser);
  }

  return { ...parsedData, ...responseDataParser(rawData) };
};

export const responseDataParser = (data: ResponseRawData): ResponseData => {
  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(responseDataParser);
  }

  return Object.keys(data).reduce((parsedData, currentField) => {
    const currentFieldData = data[currentField];

    if (FieldsToSkip.includes(currentField)) {
      return parsedData;
    }

    if (FieldsToFlat.includes(currentField)) {
      return flatData(currentFieldData, parsedData);
    }

    if (currentFieldData?.data !== undefined) {
      return {
        ...parsedData,
        [currentField]: responseDataParser(currentFieldData?.data),
      };
    }

    if (
      typeof currentFieldData === "object" ||
      Array.isArray(currentFieldData)
    ) {
      return {
        ...parsedData,
        [currentField]: responseDataParser(currentFieldData),
      };
    }

    return { ...parsedData, [currentField]: currentFieldData };
  }, {});
};
