import React from "react";
import { Stack, Avatar, Link as MuiLink } from "@mui/material";
import { AvatarProps } from "@mui/material/Avatar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FACEBOOK, INSTAGRAM, LINKEDIN } from "../../constants/config";

const socialLinks = [
  {
    href: FACEBOOK,
    icon: <FacebookIcon />,
    label: "Facebook",
  },
  {
    href: INSTAGRAM,
    icon: <InstagramIcon />,
    label: "Instagram",
  },
  {
    href: LINKEDIN,
    icon: <LinkedInIcon />,
    label: "LinkedIn",
  },
];

type Props = AvatarProps;

export function SocialLinks(props: Props) {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      {socialLinks.map((social) => (
        <MuiLink
          key={social.label}
          href={social.href}
          target="_blank"
          aria-label={social.label}
        >
          <Avatar {...props}>{social.icon}</Avatar>
        </MuiLink>
      ))}
    </Stack>
  );
}
