import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Id } from "../../typings";

type Props = {
  open: boolean;
  fullName: string;
  email: string;
  bookId: Id;
  onClose: () => void;
};
export function BookCongratsModal({
  open,
  onClose,
  bookId,
  email,
  fullName,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Order #{bookId} confirmed
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography variant="subtitle1" gutterBottom>
            Thank you {fullName}!
          </Typography>
          <Typography variant="body1" component="div">
            We`ve sent a confirmation email to {email} with coach contact
            information.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ textAlign: "center", p: 2 }}>
        <Button autoFocus variant="contained" onClick={onClose}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
