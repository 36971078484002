import React from "react";
import { useTitle } from "react-use";

import { ROUTS } from "../../common/constants/config";
import { useRedirectIfNotAuth } from "../../common/hooks/useRedirectIfNotAuth";
import { useFetchOrders } from "../../common/hooks/useFetchOrders";
import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { BookedSessions } from "./BookedSessions";

type Props = {
  type: string;
};
export function BookedSessionsContainer({ type }: Props) {
  const { orders, isLoading, error } = useFetchOrders(type);
  useTitle("Booked Sessions");
  useRedirectIfNotAuth(ROUTS.SIGN_IN);
  const title = type === "coach" ? "Trainee Sessions" : "My Sessions";
  const empty =
    type === "coach"
      ? "No sessions booked yet."
      : " You haven't booked any training sessions yet.";

  return (
    <PageContainer loading={isLoading} error={Boolean(error)}>
      <BookedSessions orders={orders} title={title} empty={empty} />
    </PageContainer>
  );
}
