import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { SignUpFormData } from "./types";
import { API_ENDPOINTS, API_URL } from "../../common/constants/config";
import { useAuthSignIn } from "../../common/hooks/useAuthSignIn";
import { AuthResponse } from "../../typings";
import { enqueueSnackbar } from "notistack";

interface UseSignUpResult {
  signUp: (data: SignUpFormData) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
}

const toSignUpData = (data: SignUpFormData) => {
  return {
    username: data.email,
    email: data.email,
    password: data.password,
    phoneNumber: data.phoneNumber,
    userRole: data.userRole,
    fullName: data.fullName,
  };
};

export const useSignUp = (redirect: string): UseSignUpResult => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const signIn = useAuthSignIn();

  const signUp = async (data: SignUpFormData) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post<AuthResponse>(
        `${API_URL}${API_ENDPOINTS.SIGN_UP}`,
        toSignUpData(data),
      );

      signIn(response.data);

      navigate(redirect);
    } catch (err: any) {
      setError(err);

      const errorMessage =
        err?.response?.data?.error?.message || "Sign up error.";

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });

      console.error("Sign up error:", err);
    } finally {
      setLoading(false);
    }
  };

  return { signUp, isLoading, error };
};
