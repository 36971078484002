import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, FormControl, ImageList, Typography } from "@mui/material";
import { Media } from "../../../typings";
import { AccountGalleryImage } from "./AccountGalleryImage";

interface GalleryProps {
  initialImages?: Media[];
  onChange?: (data: { files: File[]; media: Media[] }) => void;
}

export const AccountGallery: React.FC<GalleryProps> = ({
  initialImages = [],
  onChange,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [media, setMedia] = useState<Media[]>(initialImages);

  useEffect(() => {
    onChange && onChange({ files, media });
  }, [files, media, onChange]);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles((prevImages) => [...prevImages, ...acceptedFiles].slice(0, 10));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    maxSize: 5000000, // 5 megabytes
  });

  const removeItem = useCallback(
    (name: string, setFunc: any, idField: string) =>
      setFunc((items: any[]) => items.filter((item) => item[idField] !== name)),
    [],
  );

  return (
    <Box>
      <ImageList variant="masonry" cols={3} gap={8}>
        {media.map((image) => (
          <AccountGalleryImage
            key={image.url}
            url={image.url}
            name={image.url}
            onRemove={(name) => removeItem(name, setMedia, "url")}
          />
        ))}
        {files.map((image) => (
          <AccountGalleryImage
            key={image.name}
            url={URL.createObjectURL(image)}
            name={image.name}
            onRemove={(name) => removeItem(name, setFiles, "name")}
          />
        ))}
      </ImageList>
      <FormControl fullWidth margin="normal">
        <Box
          {...getRootProps()}
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: "grey.400",
            p: 4,
            cursor: "pointer",
            textAlign: "center",
            "&:hover": {
              borderColor: "#131313",
            },
          }}
        >
          <input {...getInputProps()} />
          <Typography
            variant="button"
            component="div"
          >{`Drag 'n' drop or click to select photos`}</Typography>
          <Typography variant="caption">
            Only JPEG and PNG images up to 5MB each. Max 10 images
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );
};
