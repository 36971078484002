import { useMemo } from "react";
import { useApiRequest } from "../../common/hooks/useApiRequest";
import { API_ENDPOINTS } from "../../common/constants/config";

const defaultParams = {
  populate: ["seo"],
  publicationState: "live",
};

export const useStaticData = (slug: string) => {
  const params = useMemo(
    () => ({
      ...defaultParams,
      filters: {
        slug: {
          $eq: slug,
        },
      },
    }),
    [slug],
  );

  const { data, loaded, error } = useApiRequest({
    url: API_ENDPOINTS.STATIC_PAGES,
    method: "GET",
    params,
  });

  const pageData = data?.length ? data[0] : null;
  const notFoundError = pageData ? null : "404";

  return { loaded, data: pageData, error: error || notFoundError };
};
