import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import { debounce } from "@mui/material/utils";
import Typography from "@mui/material/Typography";
import { Configuration, GeocodingApi } from "@stadiamaps/api";
import { PeliasGeoJSONFeature } from "@stadiamaps/api/dist/generated/models/PeliasGeoJSONFeature";

import { STADIAMAPS_API_KEY } from "../../common/constants/config";
import { Geo } from "../../typings";

const config = new Configuration({
  basePath: "https://api-eu.stadiamaps.com",
  ...(STADIAMAPS_API_KEY ? { apiKey: STADIAMAPS_API_KEY } : {}),
});
const api = new GeocodingApi(config);

type GeoOption = {
  label: string;
  id: string;
  value: Geo;
};

type Props = {
  onChange: (geo: Geo | null) => void;
  value?: Geo;
};

const geoToOption = (geo: Geo, id?: string): GeoOption => {
  return {
    id: id || `${geo.locality}-${geo.country}`,
    label: [geo.locality, geo.country].join(", "),
    value: geo,
  };
};

const prepareGeoOptions = (features: PeliasGeoJSONFeature[]): GeoOption[] => {
  const uniqueIds = new Set<string>();
  const geoOptions: GeoOption[] = [];

  features.forEach(({ properties }) => {
    const { locality, country } = properties || {};
    const id = `${locality}-${country}`;

    if (!uniqueIds.has(id) && locality) {
      uniqueIds.add(id);
      geoOptions.push(geoToOption({ locality, country }, id));
    }
  });

  return geoOptions;
};

export function GeoAutocomplete({ onChange, value }: Props) {
  const [geoValue, setGeoValue] = useState<GeoOption | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly GeoOption[]>([]);
  const [loading, setLoading] = useState(false);
  const outerValue =
    value?.locality || value?.country ? geoToOption(value) : null;

  const currentValue = geoValue || outerValue;

  const fetch = useMemo(
    () =>
      debounce(async (text: string) => {
        setLoading(true);

        if (!text) {
          setLoading(false);
          setOptions([]);
          return;
        }

        try {
          const response = await api.autocomplete({
            text,
            layers: ["country", "locality"],
            lang: "en",
          });
          const geoOptions = prepareGeoOptions(response.features);
          setOptions(geoOptions);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }, 1000),
    [],
  );

  useEffect(() => {
    if (inputValue === "") {
      setOptions(currentValue ? [currentValue] : []);
      return undefined;
    }

    fetch(inputValue);
  }, [geoValue, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      loading={loading}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={currentValue}
      noOptionsText="No locations"
      onChange={(event: any, newValue: GeoOption | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue?.value || null);
        setGeoValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter city first, then country"
          fullWidth
          margin="normal"
        />
      )}
      renderOption={(props, { value, id }) => {
        return (
          <li {...props} key={id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {value.locality || ""}
                <Typography variant="body2" color="text.secondary">
                  {value.country || ""}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
