import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import { API_URL } from "../constants/config";
import { Response } from "../../typings";
import { responseDataParser } from "../helpers/responseDataParser";

export const usePostForm = (endpoint: string) => {
  const [response, setResponse] = useState<Response | null>(null);
  const [error, setError] = useState("");
  const [data, setData] = useState<object | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!data) return;

    (async () => {
      try {
        const response: AxiosResponse<Response> = await axios.request({
          method: "POST",
          url: `${API_URL}${endpoint}`,
          data,
        });

        setResponse(response.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    })();
  }, [data]);

  return {
    setData,
    response: responseDataParser(response),
    error,
    loaded,
  };
};
