import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

import { AuthUser, BookFormData, BookFormFields, Coach } from "../../typings";
import { useCurrentUser } from "../../common/hooks/useCurrentUser";

export const bookFormId = "book-form";
const weekDaysOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const timeOptions = ["8-12", "12-16", "16-20", "20-24", "Full day"];
const CHILDREN = "Children";
const ADULTS = "Adults";

const defaultFormValues = {
  [BookFormFields.WeekDays]: [],
  [BookFormFields.Time]: [],
};

const getDefaultValues = (user: AuthUser, children: boolean) => {
  return {
    ...defaultFormValues,
    [BookFormFields.FullName]: user?.fullName || "",
    [BookFormFields.Email]: user?.email || "",
    [BookFormFields.PhoneNumber]: user?.phoneNumber || "",
    [BookFormFields.Age]: children ? CHILDREN : ADULTS,
  };
};

type Props = { coach: Coach; onBook: (data: BookFormData) => void };

export const BookForm = ({ coach, onBook }: Props) => {
  const user = useCurrentUser();
  const { children, adults } = coach || {};

  const { handleSubmit, control, getValues } = useForm<BookFormData>({
    defaultValues: getDefaultValues(user, children),
  });
  const onSubmit: SubmitHandler<BookFormData> = (data) => onBook(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={bookFormId}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={BookFormFields.FullName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <TextField label="Full name" required {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={BookFormFields.Email}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <TextField label="Email" required {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={BookFormFields.PhoneNumber}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <MuiTelInput {...field} required label="Phone number" />
                <FormHelperText>
                  Please provide your mobile number, it will help coaches to
                  connect with you quickly
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" component="div" sx={{ pb: 2 }}>
            Select one or a few preferred week days for your trainings:
          </Typography>

          <Controller
            name={BookFormFields.WeekDays}
            control={control}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="days-label">Week days</InputLabel>

                <Select
                  labelId="days-label"
                  label="Week days"
                  multiple
                  renderValue={(selected) => selected.join(", ")}
                  {...field}
                >
                  {weekDaysOptions.map((option) => {
                    const values = getValues([BookFormFields.WeekDays]);
                    const selected = ((values && values[0]) || []) as string[];

                    return (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" component="div" sx={{ pb: 2 }}>
            Select one or a few preferred time slots for your trainings:
          </Typography>

          <Controller
            name={BookFormFields.Time}
            control={control}
            render={({ field }) => (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="time-label">Time slots</InputLabel>
                <Select
                  labelId="time-label"
                  label="Time slots"
                  multiple
                  renderValue={(selected) => selected.join(", ")}
                  {...field}
                  onChange={(event) => {
                    const selectedValues = event.target.value as string[];
                    if (selectedValues.includes("Full day")) {
                      field.onChange(["Full day"]);
                    } else if (field.value.includes("Full day")) {
                      field.onChange(
                        selectedValues.filter((value) => value !== "Full day"),
                      );
                    } else {
                      field.onChange(selectedValues);
                    }
                  }}
                >
                  {timeOptions.map((option) => {
                    const values = getValues([BookFormFields.Time]);
                    const selected = ((values && values[0]) || []) as string[];
                    return (
                      <MenuItem
                        key={option}
                        value={option}
                        disabled={
                          field.value.includes("Full day") &&
                          option !== "Full day"
                        }
                      >
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option) > -1} />
                        </ListItemIcon>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name={BookFormFields.Age}
            control={control}
            render={({ field }) => (
              <FormControl>
                <Typography variant="body1" component="div" gutterBottom>
                  Select the age category that is appropriate for you:
                </Typography>
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={CHILDREN}
                    control={<Radio />}
                    label="Children (3-17)"
                    disabled={!children}
                  />
                  <FormControlLabel
                    value={ADULTS}
                    control={<Radio />}
                    label="Adults (18+)"
                    disabled={!adults}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};
