import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Coach } from "../../typings";
import { ROUTS } from "../../common/constants/config";
import defaultAvatar from "../../common/images/default-avatar.svg";
import Carousel from "react-material-ui-carousel";

const HomeCarouselItem: React.FC<{ coach: Coach }> = ({ coach }) => {
  const navigate = useNavigate();
  const { id, fullName, avatar, sportType } = coach;

  return (
    <Grid key={id} item xs={12} sm={6} md={3}>
      <Card
        sx={{
          borderRadius: 8,
          backgroundColor: "secondary.main",
          boxShadow: "none",
          height: 230
        }}
        onClick={() => navigate(`${ROUTS.COACH}/${id}`)}
      >
        <CardContent
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
        >
          <Avatar
            alt={fullName}
            src={`${avatar?.url}` || defaultAvatar}
            sx={{ width: 100, height: 100 }}
          />
          <Box sx={{ pt: 2, textAlign: "center" }}>
            <Typography variant="body1" component="h4">
              {fullName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {sportType.name} coach
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const HomeCarousel: React.FC<{ coaches: Coach[] }> = ({ coaches }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  const itemsPerSlide = matchesMD ? 4 : matchesSM ? 2 : 1;

  const groupedCoaches = [];
  for (let i = 0; i < coaches.length; i += itemsPerSlide) {
    groupedCoaches.push(coaches.slice(i, i + itemsPerSlide));
  }

  return (
    <Carousel>
      {groupedCoaches.map((group, index) => (
        <Grid key={index} container sx={{ pt: 2 }} spacing={3}>
          {group?.map((coach) => (
            <HomeCarouselItem key={coach.id} coach={coach} />
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};
