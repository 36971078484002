import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
type Props = {
  title?: string;
  children: React.ReactNode;
};

export const AccountCard = ({ children, title }: Props) => {
  return (
    <Card
      sx={{
        borderRadius: 8,
        backgroundColor: "secondary.main",
        boxShadow: "none",
        my: 2,
        p: { md: 2 },
      }}
    >
      <CardContent>
        {title && (
          <Typography sx={{ mb: 4 }} variant="h6" component="div">
            {title}
          </Typography>
        )}
        <Grid container spacing={2}>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
