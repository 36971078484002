import React from "react";
import { Button } from "@mui/material";

import ReactCookieConsent from "react-cookie-consent";

export function CookieConsent() {
  return (
    <ReactCookieConsent
      location="bottom"
      cookieName="bro-cookie"
      expires={365}
      ButtonComponent={Button}
      disableButtonStyles={true}
      customButtonProps={{ variant: "contained", sx: { m: 2 } }}
    >
      This website uses cookies to enhance the user experience.
    </ReactCookieConsent>
  );
}
