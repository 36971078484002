import React from "react";
import { Typography } from "@mui/material";

export function PageContainerError() {
  return (
    <>
      <Typography variant="h1" gutterBottom>
        Oops!
      </Typography>
      <Typography variant="h3" gutterBottom>
        Something went wrong.
      </Typography>
    </>
  );
}
