import React from "react";
import { IconButton, Menu, MenuItem, Typography, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { NavItem } from "./Header";

interface Props {
  menuItems: NavItem[];
  anchorEl: null | HTMLElement;
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
}

export const HeaderMobileMenu = ({
  menuItems,
  anchorEl,
  onOpen,
  onClose,
}: Props) => (
  <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={onOpen}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      {menuItems.map(({ to, text }) => (
        <MenuItem key={to} onClick={onClose} component={RouterLink} to={to}>
          <Typography textAlign="center">{text}</Typography>
        </MenuItem>
      ))}
    </Menu>
  </Box>
);
