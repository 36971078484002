import React from "react";
import { useTitle } from "react-use";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { Static } from "./Static";
import { useStaticData } from "./useStaticData";

type Props = { slug: string };

export function StaticContainer({ slug }: Props) {
  const { loaded, data, error } = useStaticData(slug);
  useTitle(data?.seo?.title || data?.title || 404);

  return (
    <PageContainer loading={!loaded} error={Boolean(error)}>
      {loaded && <Static data={data} />}
    </PageContainer>
  );
}
