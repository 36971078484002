import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#E26163",
    },
    secondary: {
      main: "#FBF3EB",
    },
    text: {
      primary: "#131313",
      secondary: "#383838",
    },
  },
  typography: {
    fontFamily: ['"Poppins"', '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ",",
    ),
  },
});
