import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";

import { ROUTS, UPLOAD_URL } from "../../common/constants/config";
import { HomeData } from "../../typings";
import { HomeCarousel } from "./HomeCarousel";

type Props = { data: HomeData };

export function Home({ data }: Props) {
  const { details, service, coach, coaches } = data || {};

  if (!data) return null;

  return (
    <>
      {coaches?.length > 0 && (
        <>
          <Typography
            sx={{
              fontWeight: 600,
              pb: 4,
              textAlign: "center",
              fontSize: { xs: "1.5rem", md: "2rem" },
              textTransform: "uppercase",
            }}
            gutterBottom
          >
            Our coaches
          </Typography>
          {coaches && <HomeCarousel coaches={coaches} />}
        </>
      )}

      {details?.items?.length > 0 && (
        <>
          <Typography
            sx={{
              fontWeight: 600,
              pt: 4,
              textAlign: "center",
              fontSize: { xs: "1.5rem", md: "2rem" },
              textTransform: "uppercase",
            }}
            gutterBottom
          >
            {details?.title}
          </Typography>
          <Grid
            container
            sx={{ py: 5, height: { xs: "auto", md: "100%" } }}
            spacing={{ xs: 4, md: 0 }}
          >
            <Grid item xs={12} md={7}>
              {details?.items?.map(({ id, title, text, image }) => (
                <Grid container key={id} sx={{ py: 2 }}>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ textAlign: { xs: "center", sm: "left" } }}
                  >
                    <img
                      src={`${image?.url}`}
                      alt={details?.title}
                      width={image?.width}
                      height={image?.height}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      component="h3"
                      variant="body1"
                      sx={{ mb: 0.5, fontSize: "1.2rem" }}
                      // textAlign={{ xs: "center", sm: "left" }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="body2"
                      sx={{ fontSize: "1rem" }}
                      // textAlign={{ xs: "center", sm: "left" }}
                    >
                      {text}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  minHeight: { xs: 300, md: "100%" },
                  height: "100%",
                  objectFit: "cover",
                  backgroundImage: `url(${details.image?.url})`,
                  backgroundPosition: { xs: "5% 5%", md: "500px 0" },
                  backgroundSize: "cover",
                  borderRadius: 8,
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container sx={{ py: 3 }} spacing={3}>
        <Grid item xs={12}>
          <Typography
            textTransform="uppercase"
            gutterBottom
            sx={{
              fontWeight: 600,
              pt: 4,
              textAlign: "center",
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
          >
            {service?.title}
          </Typography>
        </Grid>
        {service?.items?.map(({ id, text, title }, index) => (
          <Grid key={id} item xs={12} sm={6}>
            <Paper
              sx={{
                p: 2,
                minHeight: { sm: 200 },
                borderRadius: 8,
                backgroundColor: "secondary.main",
                boxShadow: "none",
              }}
            >
              <Avatar
                sx={{ bgcolor: "common.white", color: "primary.main", mb: 2 }}
              >
                {index + 1}
              </Avatar>
              <Typography component="h3" sx={{ mb: 1, fontSize: "1.2rem" }}>
                {title}
              </Typography>
              <Typography component="h3" sx={{ fontSize: "1.1rem" }}>
                {text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Typography
        component="h3"
        variant="h5"
        textTransform="uppercase"
        sx={{
          fontWeight: 600,
          pt: 5,
          mb: 5,
          textAlign: "center",
          fontSize: { xs: "1.5rem", md: "2rem" },
        }}
      >
        {coach?.title}
      </Typography>

      <Grid
        container
        sx={{
          py: 3,
          alignItems: "center",
        }}
        spacing={3}
      >
        {coach?.image && (
          <Grid item xs={12} sm={6} sx={{ p: 2 }}>
            <ImageListItem>
              <img
                src={`${coach?.image?.url}`}
                alt={details?.title}
                style={{ borderRadius: "30px", height: '350px', objectPosition: '0 20%' }}
              />
            </ImageListItem>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Typography
            component="h3"
            variant="h6"
            gutterBottom
            sx={{ mb: 1, fontWeight: 400 }}
          >
            {coach?.text}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={ROUTS.SIGN_UP}
            size="large"
          >
            Start as a coach
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
