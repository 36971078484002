import { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";

import { RequestParams, Response } from "../../typings";
import { responseDataParser } from "../helpers/responseDataParser";
import { API_URL } from "../constants/config";

export const useApiRequest = ({
  url,
  method,
  data = null,
  params,
  dataParser = responseDataParser,
}: RequestParams) => {
  const [responseData, setResponseData] = useState<Response | null>(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());

  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<Response> = await axios.request({
          signal: controllerRef.current.signal,
          url: `${API_URL}${url}`,
          data,
          params,
          method,
        });

        setResponseData(response.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    })();
  }, [params]);

  return {
    data: responseData && dataParser(responseData),
    pagination: responseData?.meta.pagination,
    cancel,
    error,
    loaded,
  };
};
