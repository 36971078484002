import { NavItem } from "../components/Header/Header";
import { getSiteUrl } from "../utils/utils";
import { PriceListItem } from "../../typings";

export const API_URL = process.env.REACT_APP_API_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const SITE_URL = getSiteUrl();
export const STADIAMAPS_API_KEY = process.env.REACT_APP_STADIAMAPS_API_KEY;

export const LOCALE = "en-GB";
export const CURRENCY = "EUR";

export const COACHES_PER_PAGE = 10;

export const EMAIL = "support@brotrainings.com";
export const FACEBOOK = "https://www.facebook.com/brotrainings/";
export const INSTAGRAM = "https://www.instagram.com/brotrainings/";
export const LINKEDIN = "https://www.linkedin.com/company/brotrainings/";

export const API_ENDPOINTS = {
  COACHES: "/coaches",
  SPORT_TYPES: "/sport-types",
  LANGUAGES: "/languages",
  HOME: "/home",
  STATIC_PAGES: "/static-pages",
  ORDERS: "/orders",
  SIGN_UP: "/auth/local/register",
  SIGN_IN: "/auth/local",
  ME: "/users/me",
  UPLOAD: "/upload",
};
export const ROUTS = {
  HOME: "/",
  COACHES: "/coaches",
  COACH: "/coach",
  ABOUT: "/about",
  CONTACTS: "/contacts",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  COACH_REGISTRATION: "/coach-registration",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  PASSWORD_RESET: "/password-reset",
  BOOK: "/book",
  MY_SESSIONS: "/my-sessions",
  TRAINEE_SESSIONS: "/trainee-sessions",
  ACCOUNT: "/account",
};

export const menuItems: NavItem[] = [
  { text: "Coaches", to: ROUTS.COACHES },
  { text: "About", to: ROUTS.ABOUT },
];

export const userMenuItems: NavItem[] = [
  { text: "My Account", to: ROUTS.ACCOUNT },
  { text: "My Sessions", to: ROUTS.MY_SESSIONS },
];

export const coachMenuItems: NavItem[] = [
  { text: "Trainee Sessions", to: ROUTS.TRAINEE_SESSIONS },
];
export const getUserMenuItems = (isCoach: boolean) => {
  if (isCoach) {
    return [...userMenuItems, ...coachMenuItems];
  }
  return userMenuItems;
};
export const testCall: PriceListItem = {
  id: 0,
  price: 0,
  description:
    "If you have any questions before buying the training, you can book a free call with me",
  title: "Consultation Call",
  duration: 15,
};
