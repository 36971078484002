import React from "react";
import ReactMarkdown from "react-markdown";
import { Typography } from "@mui/material";

import { StaticData } from "../../typings";

type Props = { data: StaticData };

export function Static({ data }: Props) {
  const { title, content } = data;

  return (
    <>
      <Typography variant="h4" component="h5" gutterBottom>
        {title}
      </Typography>
      <ReactMarkdown>{content}</ReactMarkdown>
    </>
  );
}
