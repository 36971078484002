import React from "react";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { ROUTS } from "../../common/constants/config";
import { SignInForm } from "./SignInForm";
import { useSignIn } from "./useSignIn";
import { SignInFormData } from "./types";

export function SignInContainer() {
  const { isLoading, error, signIn } = useSignIn(ROUTS.COACHES);
  const handleLogIn = async (data: SignInFormData) => {
    await signIn(data);
  };

  return (
    <PageContainer loading={isLoading}>
      <SignInForm onLogIn={handleLogIn} isValidationError={Boolean(error)} />
    </PageContainer>
  );
}
