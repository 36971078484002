import React from "react";
import { Coach } from "../../typings";
import { CoachItem } from "./CoachItem";
import { Grid } from "@mui/material";

type Props = { coaches?: Coach[] };

export const CoachList = ({ coaches }: Props) => {
  return (
    <Grid container spacing={4}>
      {coaches?.map((coach) => <CoachItem coach={coach} key={coach.id} />)}
    </Grid>
  );
};
