import { useSignIn } from "react-auth-kit";

import { AuthResponse } from "../../typings";

export const useAuthSignIn = () => {
  const signIn = useSignIn();

  return (data: AuthResponse) =>
    signIn({
      token: data.jwt,
      expiresIn: 40320, // in minutes
      tokenType: "Bearer",
      authState: data.user,
    });
};
