import React from "react";
import { Link, Typography } from "@mui/material";
import { ROUTS } from "../../constants/config";
import { Link as RouterLink } from "react-router-dom";

export function Agreement({
  text = "By using this site, you agree to our",
  align = "left",
}: {
  text?: string;
  align?: "left" | "right" | "center";
}) {
  return (
    <Typography variant="body2" color="text.secondary" align={align}>
      {text} &nbsp;
      <Link to={ROUTS.PRIVACY_POLICY} component={RouterLink} underline="hover">
        Privacy Policy
      </Link>
      &nbsp; and &nbsp;
      <Link to={ROUTS.TERMS_OF_USE} component={RouterLink} underline="hover">
        Terms of Use
      </Link>
      .
    </Typography>
  );
}
