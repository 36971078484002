import React from "react";

import { ROUTS } from "../../common/constants/config";
import { useRedirectIfNotAuth } from "../../common/hooks/useRedirectIfNotAuth";
import { useCurrentUser } from "../../common/hooks/useCurrentUser";
import { AccountTrainee } from "./AccountTrainee/AccountTrainee";
import { AccountCoachContainer } from "./AccountCoach/AccountCoachContainer";
import { useTitle } from "react-use";

export function AccountContainer() {
  const user = useCurrentUser();
  useTitle("My Account");
  useRedirectIfNotAuth(ROUTS.SIGN_IN);

  if (!user) {
    return null;
  }

  if (user.userRole === "coach") {
    return <AccountCoachContainer />;
  }

  return <AccountTrainee user={user} />;
}
