import React from "react";
import { useTitle } from "react-use";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { ROUTS } from "../../common/constants/config";
import { SignUpForm } from "./SignUpForm";
import { useSignUp } from "./useSignUp";
import { SignUpFormData } from "./types";

export function SignUpContainer() {
  const { signUp, isLoading } = useSignUp(ROUTS.ACCOUNT);
  useTitle("Sign up");
  const handleSignIn = async (data: SignUpFormData) => {
    await signUp(data);
  };
  return (
    <PageContainer loading={isLoading}>
      <SignUpForm onSignUp={handleSignIn} />
    </PageContainer>
  );
}
