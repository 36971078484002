import React from "react";
import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { EMAIL, ROUTS } from "../../constants/config";
import { SocialLinks } from "../SocialLinks/SocialLinks";

export function Footer() {
  return (
    <Box sx={{ backgroundColor: "secondary.main", p: 6 }} component="footer">
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Link
            color="text.secondary"
            underline="hover"
            href={`mailto:${EMAIL}`}
          >
            {EMAIL}
          </Link>
        </Grid>

        <Grid item xs={12} sx={{ py: 3 }}>
          <SocialLinks sx={{ bgcolor: "primary.main" }} />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            component="nav"
            gutterBottom
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{
                justifyContent: "center",
              }}
            >
              <Link
                to={ROUTS.PRIVACY_POLICY}
                component={RouterLink}
                color="text.secondary"
                underline="hover"
              >
                Privacy Policy
              </Link>
              <Link
                to={ROUTS.TERMS_OF_USE}
                component={RouterLink}
                color="text.secondary"
                underline="hover"
              >
                Terms of Use
              </Link>
            </Stack>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary" align="center">
            {`© ${new Date().getFullYear()} BRO inc.`} <br />
            All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
