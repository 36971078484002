import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthHeader } from "react-auth-kit";
import { API_ENDPOINTS, API_URL } from "../constants/config";

export const usePostCoachAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const authHeader = useAuthHeader();

  const createAccountCoach = async (data: any) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${API_URL}${API_ENDPOINTS.COACHES}`,
        {
          data: {
            ...data,
            publishedAt: null,
          },
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        },
      );
      enqueueSnackbar("Account successfully created", { variant: "success" });
      return response.data;
    } catch (err: any) {
      setError(err);
      enqueueSnackbar("Error creating account", { variant: "error" });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { createAccountCoach, isLoading, error };
};
