import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCurrentUser } from "./useCurrentUser";

export const useRedirectIfNotAuth = (redirectUrl: string) => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  useEffect(() => {
    if (!user) {
      navigate(redirectUrl);
    }
  }, [user, navigate]);
};
