import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";

import { Coach } from "../../typings";
import { UPLOAD_URL } from "../../common/constants/config";

type Props = { gallery: Coach["gallery"] };

export function CoachGallery({ gallery }: Props) {
  return (
    <Carousel>
      {gallery?.map((media) => (
        <Box
          key={media.id}
          sx={{
            height: { md: 600, xs: 300 },
            pt: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            height="100%"
            key={media.id}
            src={`${UPLOAD_URL + media.url}`}
            srcSet={`${UPLOAD_URL + media.url}`}
            alt={media.caption || ""}
            loading="lazy"
            style={{ borderRadius: 20, maxWidth: "100%" }}
          />
        </Box>
      ))}
    </Carousel>
  );
}
