import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../constants/config";
import { Order } from "../../typings";

export const fetchOrders = (authHeader: string, type: string) =>
  axios.get<{ data: Order[] }>(`${API_URL}${API_ENDPOINTS.ORDERS}`, {
    headers: {
      Authorization: authHeader,
    },
    params: {
      type,
      sort: ["createdAt:desc"],
      pagination: {
        pageSize: 10000,
        page: 1,
      },
    },
  });
