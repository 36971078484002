import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthHeader } from "react-auth-kit";
import { API_ENDPOINTS, API_URL } from "../constants/config";
import { Id } from "../../typings";

export const usePutCoachAccount = (coachId?: Id) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const authHeader = useAuthHeader();

  const updateAccountCoach = async (data: any) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.put(
        `${API_URL}${API_ENDPOINTS.COACHES}/${coachId}`,
        { data },
        {
          headers: {
            Authorization: authHeader(),
          },
        },
      );
      enqueueSnackbar(
        "Your profile has been successfully updated and submitted for validation. The validation process takes no more than 24 hours.",
        { variant: "success" },
      );
      return response.data;
    } catch (err: any) {
      setError(err);
      enqueueSnackbar("Error updating account", { variant: "error" });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateAccountCoach, isLoading, error };
};
