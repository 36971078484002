import React from "react";
import { Typography } from "@mui/material";

export function CoachListEmpty() {
  return (
    <Typography variant="body1">
      No coaches here at the moment. Try another location or sport type.
    </Typography>
  );
}
