import React from "react";
import { Grid, Typography } from "@mui/material";
type Props = {
  label?: string;
  value?: any;
  children?: React.ReactNode;
};
export const AccountItem = ({ label, value, children }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" color="text.secondary">
        {children ? children : `${label}: ${value}`}
      </Typography>
    </Grid>
  );
};
