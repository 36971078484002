import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import aboutImage from "./images/about.jpg";
import './AboutHeader.css'

export function AboutHeadline() {
  const [cachedImage, setCachedImage] = useState<string | null>(null);

  useEffect(() => {
    const aboutHeaderImage = aboutImage;
    const cachedUrl = localStorage.getItem(aboutHeaderImage);
    if (cachedUrl) {
      setCachedImage(cachedUrl);
    } else {
      const img = new Image();
      img.src = aboutHeaderImage;
      img.onload = () => {
        localStorage.setItem(aboutHeaderImage, aboutHeaderImage);
        setCachedImage(aboutHeaderImage);
      };
    }
  }, []);

  return (
    <Box
      className="headerImg"
      sx={{
        backgroundImage: `url(${cachedImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "primary.main",
        minHeight: { sm: "450px", xs: "300px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
  );
}