export const ageOutput = (children?: boolean, adults?: boolean): string => {
  if (children && adults) {
    return "I coach children and adults";
  }

  if (children) {
    return "I coach children ages 3-17";
  }

  return "I coach adults ages 18+";
};
