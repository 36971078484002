import React, { useCallback, useEffect, useState } from "react";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { CoachRegistrationForm } from "./CoachRegistrationForm";
import { CoachRegistrationFormData } from "../../typings";
import { usePostForm } from "../../common/hooks/usePostForm";
import { API_ENDPOINTS, ROUTS } from "../../common/constants/config";
import { CoachRegistrationCongratsModal } from "./CoachRegistrationCongratsModal";
import { useNavigate } from "react-router-dom";
import { useTitle } from "react-use";

export function CoachRegistrationContainer() {
  const [congratsOpen, setCongratsOpen] = useState(false);
  const {
    response: registerResponse,
    setData: createCoach,
    loaded: registered,
  } = usePostForm(API_ENDPOINTS.COACHES);
  const navigate = useNavigate();

  useTitle("Register as a coach");

  useEffect(() => {
    if (registerResponse) {
      setCongratsOpen(true);
    }
  }, [registered]);

  const handleRegister = useCallback(
    (data: CoachRegistrationFormData) =>
      createCoach({ data: { ...data, publishedAt: null } }),
    [],
  );

  const handleCongratsClose = () => {
    setCongratsOpen(false);
    navigate(ROUTS.COACHES);
  };

  // todo: add error component
  return (
    <PageContainer>
      <CoachRegistrationForm onRegister={handleRegister} />
      {registerResponse && (
        <CoachRegistrationCongratsModal
          open={congratsOpen}
          email={registerResponse?.email}
          fullName={registerResponse?.fullName}
          onClose={handleCongratsClose}
        />
      )}
    </PageContainer>
  );
}
