import { useApiRequest } from "./useApiRequest";
import { API_ENDPOINTS } from "../constants/config";
import { Id } from "../../typings";

const defaultParams = {
  populate: [
    "avatar",
    "sportType",
    "languages",
    "gallery",
    "priceListInPerson",
    "priceListInPerson.program",
    "priceListOnline",
    "priceListOnline.program",
  ],
  publicationState: "live",
};

export const useCoachData = (id: Id) => {
  const { data, loaded, error } = useApiRequest({
    url: `${API_ENDPOINTS.COACHES}/${id}`,
    method: "GET",
    params: defaultParams,
  });

  return { data, loaded, error };
};
