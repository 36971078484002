import React from "react";
import { Outlet } from "react-router-dom";

import { Header, Footer } from "./common/components";
import { CookieConsent } from "./common/components/CookieConsent/CookieConsent";

export function Layout() {
  return (
    <>
      <Header />

      <Outlet />

      <Footer />
      <CookieConsent />
    </>
  );
}
