import React, { useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Menu,
  MenuItem,
  Box,
  ListItemText,
  Link,
} from "@mui/material";
import { formatMoney } from "../../common/helpers/formatMoney";
import { Link as RouterLink } from "react-router-dom";
import { ROUTS } from "../../common/constants/config";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Id, PriceListItem } from "../../typings";
import { findSessionWithLowestPrice } from "../Book/helpers";
import { generateTitle } from "./helpers";

const priceStyles = {
  textAlign: "center",
  backgroundColor: "secondary.main",
  boxShadow: "none",
  px: 4,
  py: 6,
  borderRadius: 8,
};

type Props = {
  title: string;
  priceList: PriceListItem[];
  coachId: Id;
  fullWidth?: boolean;
};

export const CoachPriceSelect = ({
  title,
  priceList,
  coachId,
  fullWidth,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedSession, setSelectedSession] = useState<PriceListItem>(
    findSessionWithLowestPrice(priceList),
  );

  const availableOptions = priceList.filter(
    (session) => session.id !== selectedSession.id,
  );
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
  ) => {
    const selectedSession = priceList.find((session) => session.id === id);
    if (selectedSession) {
      setSelectedSession(selectedSession);
    }
    handleMenuClose();
  };

  if (priceList.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} md={fullWidth ? 12 : 6}>
      <Paper elevation={2} sx={priceStyles}>
        <Typography variant="h4" component="div">
          {title}
        </Typography>
        <Box
          sx={{
            py: 1,
            minHeight: fullWidth ? "inherit" : 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {generateTitle(selectedSession)}
          </Typography>
          <Typography variant="body1" component="div">
            {selectedSession.description}
          </Typography>
          {selectedSession.isProgram && selectedSession?.program?.url && (
            <Typography variant="body1" component="div" sx={{ pt: 2 }}>
              <Link
                href={selectedSession?.program?.url}
                target="_blank"
                rel="noreferrer"
              >
                Training details
              </Link>
            </Typography>
          )}
        </Box>
        <Typography variant="h3" component="div" gutterBottom>
          {formatMoney(selectedSession.price)}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: fullWidth ? 0 : 2,
            pt: fullWidth ? 0 : 4,
            alignItems: "center",
          }}
        >
          <Button
            component={RouterLink}
            to={`${ROUTS.BOOK}/${coachId}/${selectedSession.id}`}
            sx={{ px: 4 }}
            variant="contained"
          >
            Book
          </Button>

          <Box sx={{ minHeight: fullWidth ? "inherit" : { md: 40 } }}>
            {priceList.length > 1 && (
              <>
                <Button
                  size="large"
                  aria-controls="session-select-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  sx={{ px: 4 }}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Other Options
                </Button>
                <Menu
                  id="session-select-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                >
                  {availableOptions.map((session, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) =>
                        handleMenuItemClick(event, Number(session.id))
                      }
                    >
                      <ListItemText>{generateTitle(session)}</ListItemText>
                      <Typography sx={{ pl: 2 }}>
                        {formatMoney(session.price)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
