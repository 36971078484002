import React from "react";

import { createBrowserRouter } from "react-router-dom";
import { NotFound } from "./pages/ErrorPage/NotFound";
import { ROUTS } from "./common/constants/config";
import { HomePage } from "./pages/HomePage/HomePage";
import { CoachListPage } from "./pages/CoachListPage/CoachListPage";
import { CoachDetailsPage } from "./pages/CoachDetailsPage/CoachDetailsPage";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { StaticPage } from "./pages/StaticPage/StaticPage";
import { BookPage } from "./pages/BookPage/CoachDetailsPage";
import { CoachRegistrationPage } from "./pages/CoachRegistrationPage/CoachRegistrationPage";
import { Layout } from "./Layout";
import { SignInPage } from "./pages/SignInPage/SignInPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";
import { BookedSessionsPage } from "./pages/BookedSessionsPage/BookedSessionsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ROUTS.HOME,
        element: <HomePage />,
      },
      {
        path: ROUTS.COACHES,
        element: <CoachListPage />,
      },
      {
        path: `${ROUTS.COACH}/:coachId`,
        element: <CoachDetailsPage />,
      },
      {
        path: ROUTS.ABOUT,
        element: <AboutPage />,
      },
      {
        path: ROUTS.PRIVACY_POLICY,
        element: <StaticPage slug="privacy-policy" />,
      },
      {
        path: ROUTS.TERMS_OF_USE,
        element: <StaticPage slug="terms-of-use" />,
      },
      {
        path: `${ROUTS.BOOK}/:coachId/:priceId`,
        element: <BookPage />,
      },
      {
        path: ROUTS.COACH_REGISTRATION,
        element: <CoachRegistrationPage />,
      },
      {
        path: ROUTS.SIGN_IN,
        element: <SignInPage />,
      },
      {
        path: ROUTS.SIGN_UP,
        element: <SignUpPage />,
      },
      {
        path: ROUTS.ACCOUNT,
        element: <AccountPage />,
      },
      {
        path: ROUTS.MY_SESSIONS,
        element: <BookedSessionsPage type="trainee" />,
      },
      {
        path: ROUTS.TRAINEE_SESSIONS,
        element: <BookedSessionsPage type="coach" />,
      },
    ],
  },
]);
