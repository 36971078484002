import { useEffect, useMemo, useState } from "react";
import { useApiRequest } from "../../common/hooks/useApiRequest";
import { Coach, Geo, Id, ResponseMeta, SportType } from "../../typings";
import { API_ENDPOINTS, COACHES_PER_PAGE } from "../../common/constants/config";

const coachesRequestDefaultParams = {
  sort: ["id:desc"],
  populate: [
    "avatar",
    "sportType",
    "languages",
    "gallery",
    "priceListOnline",
    "priceListInPerson",
  ],
  publicationState: "live",
};

const sportTypesRequestParams = {
  sort: ["name:asc"],
  pagination: {
    pageSize: 10000,
    page: 1,
  },
  publicationState: "live",
};

type Data = {
  setSportTypeId: (id?: Id) => void;
  setGeo: (id: Geo | null) => void;
  setPage: (page: number) => void;
  data: {
    coaches?: Coach[];
    sportTypes?: SportType[];
  };
  loaded: boolean;
  pagination?: ResponseMeta["pagination"];
  error: string;
};

const getCoachesRequestParams = (
  page: number,
  sportTypeId?: Id,
  geo?: Geo | null,
) => {
  const { locality, country } = geo || {};
  const pagination = { pageSize: COACHES_PER_PAGE, page };
  const filters = {
    ...(sportTypeId ? { sportType: { id: { $eq: sportTypeId } } } : {}),
    ...(locality ? { geoLocality: { $eq: locality } } : {}),
    ...(country ? { geoCountry: { $eq: country } } : {}),
  };

  return { ...coachesRequestDefaultParams, pagination, filters };
};

export const useCoachListData = (): Data => {
  const [sportTypeId, setSportTypeId] = useState<Id>();
  const [geo, setGeo] = useState<Geo | null>(null);
  const [page, setPage] = useState(1);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const coachesRequestParams = useMemo(
    () => getCoachesRequestParams(page, sportTypeId, geo),
    [triggerUpdate, page],
  );

  useEffect(() => {
    setPage(1);
    setTriggerUpdate((trigger) => !trigger);
  }, [sportTypeId, geo]);

  const {
    data: coaches,
    loaded: coachesIsLoaded,
    pagination: coachesPagination,
    error,
  } = useApiRequest({
    url: API_ENDPOINTS.COACHES,
    method: "GET",
    params: coachesRequestParams,
  });

  const { data: sportTypes, loaded: sportTypesIsLoaded } = useApiRequest({
    url: API_ENDPOINTS.SPORT_TYPES,
    method: "GET",
    params: sportTypesRequestParams,
  });

  const loaded = coachesIsLoaded && sportTypesIsLoaded;

  return {
    data: { coaches, sportTypes },
    pagination: coachesPagination,
    setSportTypeId,
    setGeo,
    setPage,
    loaded,
    error,
  };
};
