import React, { useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";

import { BookFormData, Coach, Id, PriceListItem } from "../../typings";
import { formatMoney } from "../../common/helpers/formatMoney";
import { BookForm, bookFormId } from "./BookForm";
import { Agreement } from "../../common/components/Agreement/Agreement";
import { testCall } from "../../common/constants/config";

type Props = {
  coach: Coach;
  priceId: Id;
  onBook: (data: BookFormData) => void;
};

const Insurance = () => {
  return (
    <Typography
      variant="body2"
      sx={{ border: 1, borderRadius: 1, borderColor: "grey.400", p: 2, mp: 4 }}
    >
      Stay safe during sports with insurance! It covers injuries, ensuring your
      well-being. Purchase it only if you decide you need it, through these
      recommended platforms:
      <Box sx={{ mt: 1 }}>
        <Link href="https://www.imglobal.com/" target="_blank">
          IMG Global
        </Link>
      </Box>
      <Box>
        <Link href="https://www.insure4sport.co.uk/" target="_blank">
          Insure4Sport
        </Link>
      </Box>
      <Box>
        <Link href="https://www.sportscoverdirect.com/" target="_blank">
          Sports Cover Direct
        </Link>
      </Box>
    </Typography>
  );
};

export const Book = ({ coach, priceId, onBook }: Props) => {
  const { priceListOnline = [], priceListInPerson = [] } = coach;
  const priceListItem = useMemo(() => {
    if (priceId === "0") return testCall;
    return [...priceListOnline, ...priceListInPerson].find(
      (priceListItem: PriceListItem) => String(priceListItem.id) === priceId,
    );
  }, [priceId, priceListOnline, priceListInPerson]);

  const isOffline = useMemo(() => {
    return priceListInPerson.some(
      (priceListItem: PriceListItem) => String(priceListItem.id) === priceId,
    );
  }, [priceId, priceListInPerson]);

  return (
    <Paper
      sx={{
        backgroundColor: "secondary.main",
        boxShadow: "none",
        p: 4,
        borderRadius: 8,
      }}
    >
      <Grid container spacing={6} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ pb: 3 }}>
            {priceListItem
              ? "Book your training"
              : `Book a chat with ${coach?.fullName}`}
          </Typography>
          <BookForm onBook={onBook} coach={coach} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ pb: 2 }}>
              Order details
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Box component="span" sx={{ fontWeight: 500 }}>
                Coach:
              </Box>{" "}
              {coach?.fullName}
            </Typography>
            {priceListItem ? (
              <>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500 }}
                  gutterBottom
                >
                  {priceListItem?.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {priceListItem?.description}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Box component="span" sx={{ fontWeight: 500 }}>
                    Format:{" "}
                  </Box>
                  {isOffline ? "In person (offline)" : "Online"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Box component="span" sx={{ fontWeight: 500 }}>
                    Duration:{" "}
                  </Box>
                  {priceListItem.duration} min
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <Box component="span" sx={{ fontWeight: 500 }}>
                    Price:{" "}
                  </Box>
                  {formatMoney(priceListItem?.price)}
                </Typography>
              </>
            ) : (
              <Typography variant="body1" gutterBottom>
                Unit price: {formatMoney(0)}
              </Typography>
            )}

            <Box sx={{ py: 3 }}>
              <Insurance />
            </Box>

            <FormControl sx={{ width: "100%" }}>
              <Button
                sx={{ py: 2 }}
                variant="contained"
                type="submit"
                form={bookFormId}
              >
                Book
              </Button>
              <Box sx={{ pt: 2, px: 1 }}>
                <Agreement
                  text="By booking the training you agree with our"
                  align="center"
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
