import React from "react";
import { Button, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { NavItem } from "./Header";

interface DesktopMenuProps {
  menuItems: NavItem[];
  onClose: () => void;
}

export const HeaderDesktopMenu = ({ menuItems, onClose }: DesktopMenuProps) => (
  <Box
    sx={{
      flexGrow: 1,
      display: { xs: "none", md: "flex", justifyContent: "flex-end" },
    }}
  >
    {menuItems.map(({ to, text }) => (
      <Button
        key={to}
        onClick={onClose}
        component={RouterLink}
        sx={{
          my: 2,
          color: "text.primary",
          display: "block",
          fontSize: "1.3rem",
          textTransform: "none",
        }}
        to={to}
      >
        {text}
      </Button>
    ))}
  </Box>
);
