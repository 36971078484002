import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import {
  TextField,
  Button,
  Link,
  Box,
  Typography,
  Container,
  Stack,
  Alert,
} from "@mui/material";
import { SignInFormData } from "./types";
import { ROUTS } from "../../common/constants/config";

type Props = {
  onLogIn: SubmitHandler<SignInFormData>;
  isValidationError?: boolean;
};

export function SignInForm({ onLogIn, isValidationError }: Props) {
  const { register, handleSubmit, watch } = useForm<SignInFormData>();

  const isDisabled = !watch("identifier") || !watch("password");

  const onSubmit: SubmitHandler<SignInFormData> = (data) => {
    onLogIn(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Log in
        </Typography>

        {isValidationError && (
          <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            Authentication failed. Please check your credentials.
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="Email"
            autoComplete="username"
            autoFocus
            {...register("identifier")}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isDisabled}
          >
            Log In
          </Button>
          <Stack textAlign="center" gap={1}>
            <Typography variant="body2">
              {"Don't have an account? "}
              <Link component={RouterLink} to={ROUTS.SIGN_UP} variant="body2">
                Sign Up
              </Link>
            </Typography>
            {/*<Link*/}
            {/*  component={RouterLink}*/}
            {/*  to={ROUTS.PASSWORD_RESET}*/}
            {/*  variant="body2"*/}
            {/*>*/}
            {/*  Forgot your password?*/}
            {/*</Link>*/}
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
