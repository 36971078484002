import React from "react";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "react-auth-kit";

import { router } from "./router";
import { ThemeProvider } from "./theme/ThemeProvider";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <ThemeProvider>
      <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={
          window.location.protocol === "https:" ||
          process.env.NODE_ENV === "development"
        }
      >
        <SnackbarProvider maxSnack={3}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
