import React from "react";
import { Typography, Box, Link } from "@mui/material";
import { useDropzone } from "react-dropzone";

type FileUploadFieldProps = {
  name: string;
  onChange: (file: File | null) => void;
  initialValue: any;
};
const maxSize = 10 * 1024 * 1024; // 10 MB

export const AccountFileUpload: React.FC<FileUploadFieldProps> = ({
  name,
  onChange,
  initialValue,
}) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize,
    multiple: false,
    onDrop: (acceptedFiles) => {
      onChange(acceptedFiles[0]);
      setFileName(acceptedFiles[0].name);
    },
  });
  const [fileName, setFileName] = React.useState("");

  return (
    <>
      {fileName && (
        <Typography sx={{ my: 1 }}>
          Selected file:
          <Box component="span" sx={{ color: "primary.main" }}>
            {" "}
            {fileName}
          </Box>
        </Typography>
      )}
      {initialValue && !fileName && (
        <Typography sx={{ my: 1 }}>
          <Link href={initialValue.url} target="_blank">
            {initialValue.name}
          </Link>
        </Typography>
      )}
      <Box
        {...getRootProps()}
        sx={{
          border: 1,
          borderRadius: 1,
          borderColor: "grey.400",
          p: 4,
          cursor: "pointer",
          textAlign: "center",
          "&:hover": {
            borderColor: "#131313",
          },
        }}
      >
        <input {...getInputProps()} aria-label={`Upload file for ${name}`} />
        <Typography variant="button" component="div">
          {`DRAG 'N' DROP OR CLICK TO SELECT FILE`}
        </Typography>
        <Typography variant="caption">
          Acceptable any readable file format. Max 10MB
        </Typography>

        {fileRejections.length > 0 && (
          <Typography color="error">File is too large (max 20MB)</Typography>
        )}
      </Box>
    </>
  );
};
