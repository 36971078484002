import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useAuthHeader } from "react-auth-kit";
import { API_ENDPOINTS, API_URL } from "../constants/config";

export const useUploadMedia = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const authHeader = useAuthHeader();

  const uploadMedia = async (files: File[], fileNamePrefix: string) => {
    setLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append(`files`, file);
        formData.append("name", fileNamePrefix);
      });
      formData.append("name", fileNamePrefix);

      const { data } = await axios.post(
        `${API_URL}${API_ENDPOINTS.UPLOAD}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authHeader(),
          },
        },
      );
      return data;
    } catch (err: any) {
      setError(err);
      enqueueSnackbar("Error uploading file", { variant: "error" });
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { uploadMedia, isLoading, error };
};
