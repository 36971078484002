import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { useCoachData } from "../../common/hooks/useCoachData";
import { API_ENDPOINTS, ROUTS } from "../../common/constants/config";
import { BookFormData } from "../../typings";
import { usePostForm } from "../../common/hooks/usePostForm";
import { Book } from "./Book";
import { BookCongratsModal } from "./BookCongratsModal";
import { prepareData } from "./helpers";
import { useCurrentUser } from "../../common/hooks/useCurrentUser";
import { SignInForm } from "../SignIn/SignInForm";
import { useSignIn } from "../SignIn/useSignIn";
import { SignInFormData } from "../SignIn/types";

export function BookContainer() {
  const [loading, setLoading] = useState(false);
  const { coachId, priceId } = useParams();
  const { data: coach, loaded } = useCoachData(String(coachId));
  const [congratsOpen, setCongratsOpen] = useState(false);
  const location = useLocation();

  const user = useCurrentUser();
  const {
    isLoading: signInLoading,
    error: signInError,
    signIn,
  } = useSignIn(location.pathname);

  const handleLogIn = async (data: SignInFormData) => {
    await signIn(data);
  };
  const isLoading = !loaded || loading || signInLoading;

  const navigate = useNavigate();
  const {
    response: bookResponse,
    setData: createBookOrder,
    loaded: booked,
    error,
  } = usePostForm(API_ENDPOINTS.ORDERS);

  useTitle(`Book training with ${coach?.fullName}`);

  useEffect(() => {
    if (bookResponse) {
      setLoading(false);
      setCongratsOpen(true);
    }
  }, [booked]);

  const handleCongratsClose = () => {
    setCongratsOpen(false);
    navigate(ROUTS.MY_SESSIONS);
  };

  const handleBookCoach = useCallback(
    (data: BookFormData) => {
      setLoading(true);
      createBookOrder({
        data: prepareData(
          String(coachId),
          String(user.id),
          String(priceId),
          data,
          coach,
        ),
      });
    },
    [coach],
  );

  return (
    <PageContainer loading={isLoading} error={Boolean(error)}>
      {user ? (
        coach && (
          <Book
            coach={coach}
            priceId={String(priceId)}
            onBook={handleBookCoach}
          />
        )
      ) : (
        <SignInForm
          isValidationError={Boolean(signInError)}
          onLogIn={handleLogIn}
        />
      )}
      {bookResponse && (
        <BookCongratsModal
          onClose={handleCongratsClose}
          open={congratsOpen}
          email={bookResponse?.email}
          bookId={bookResponse?.id}
          fullName={bookResponse?.fullName}
        />
      )}
    </PageContainer>
  );
}
