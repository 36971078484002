import React from "react";

import { PageContainer } from "../../common/components/PageContainer/PageContainer";

import { About } from "./About";
import { AboutHeadline } from "./AboutHeadline";

export function AboutContainer() {
  return (
    <PageContainer headline={<AboutHeadline />}>{<About />}</PageContainer>
  );
}
