import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../constants/config";
import { SportType } from "../../typings";

export const fetchLanguages = () =>
  axios.get<{ data: SportType[] }>(`${API_URL}${API_ENDPOINTS.LANGUAGES}`, {
    params: {
      sort: ["name:asc"],
      pagination: {
        pageSize: 10000,
        page: 1,
      },
      publicationState: "live",
    },
  });
