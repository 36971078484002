import React, { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Box,
  FormLabel,
  Autocomplete,
  Link,
} from "@mui/material";
import { Option } from "../../../typings";
import { UserProfileFormData } from "./typings";
import { GeoAutocomplete } from "../../CoachList/GeoAutocomplete";
import { EMAIL } from "../../../common/constants/config";
import { AccountCoachAccordion } from "./AccountCoachAccordion";
import { AccountCoachPrice } from "./AccountCoachPrice";

type Props = {
  onSubmit: (data: UserProfileFormData) => void;
  initialValues?: UserProfileFormData;
  sportTypeOptions: Option[];
  languageOptions: Option[];
  avatar?: ReactNode;
  gallery?: ReactNode;
};

export const AccountCoachForm = ({
  initialValues,
  sportTypeOptions,
  languageOptions,
  onSubmit,
  avatar,
  gallery,
}: Props) => {
  const { control, handleSubmit, register, setValue, watch } =
    useForm<UserProfileFormData>({
      defaultValues: initialValues,
    });

  const fullNameInputRef = useRef<HTMLInputElement | null>(null);
  const aboutValue = watch("about");
  const aboutLength = aboutValue ? aboutValue?.length : 0;

  useEffect(() => {
    if (fullNameInputRef?.current) {
      fullNameInputRef?.current?.focus();
    }
  }, []);

  const extendedSportTypeOptions = [
    {
      label: "Didn't find your sport? Click here...",
      value: "Other",
    },
    ...sportTypeOptions,
  ];

  const handleExperienceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(1, Math.min(99, Number(event.target.value)));
    setValue("experience", value ? value : null);
  };

  return (
    <form>
      <AccountCoachAccordion title="Contact Information">
        <TextField
          {...register("email")}
          label="Email"
          fullWidth
          margin="normal"
          disabled
          helperText={
            <>
              To change your email, please contact{" "}
              <Link
                color="text.secondary"
                underline="hover"
                href={`mailto:${EMAIL}`}
              >
                {EMAIL}
              </Link>
              .
            </>
          }
        />
        <TextField
          {...register("fullName")}
          label="Full Name"
          fullWidth
          margin="normal"
          inputRef={fullNameInputRef}
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl fullWidth margin="normal">
              <MuiTelInput {...field} label="Phone number" />
            </FormControl>
          )}
        />

        <Controller
          name="geo"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <GeoAutocomplete
                value={value}
                onChange={(geo) => onChange(geo)}
              />
            );
          }}
        />
      </AccountCoachAccordion>

      <AccountCoachAccordion title="Professional Profile">
        {avatar}

        <FormControl fullWidth margin="normal">
          <Controller
            name="sportType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={extendedSportTypeOptions}
                getOptionLabel={(option) => option.label}
                value={extendedSportTypeOptions.find(
                  (option) => option.value === value,
                )}
                onChange={(event, newValue) => {
                  onChange(newValue ? newValue.value : null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Sport Type" />
                )}
              />
            )}
          />
        </FormControl>

        {watch("sportType") === "Other" && (
          <TextField
            {...register("tempSportType")}
            label="New Sport Type"
            fullWidth
            margin="normal"
          />
        )}

        <FormControl fullWidth margin="normal">
          <Controller
            name="languages"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                options={languageOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  onChange(newValue.map((item) => item.value));
                }}
                value={languageOptions.filter(
                  (option) => value?.includes(option.value),
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Languages" />
                )}
              />
            )}
          />
        </FormControl>

        <TextField
          {...register("experience", {
            valueAsNumber: true,
            min: 1,
            max: 99,
          })}
          onChange={handleExperienceChange}
          type="text"
          label="Years of Experience"
          fullWidth
          margin="normal"
          InputProps={{ inputProps: { min: 1, max: 99 } }}
        />

        <FormControl margin="normal" fullWidth>
          <FormLabel>Age Group</FormLabel>

          <Controller
            name="children"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label="I coach children ages 3-17"
              />
            )}
          />

          <Controller
            name="adults"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label="I coach adults ages 18+"
              />
            )}
          />
        </FormControl>

        <TextField
          {...register("certificates")}
          label="Certificates"
          fullWidth
          margin="normal"
          helperText="Write your certificates separated by commas (e.g. Certificate 1, Certificate 2) "
        />

        <TextField
          {...register("about")}
          label="About"
          multiline
          rows={6}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: 1000 }}
          helperText={`Type a description about yourself for the coach page (${aboutLength}/1000 characters with spaces)`}
        />
      </AccountCoachAccordion>

      <AccountCoachAccordion title="Gallery">{gallery}</AccountCoachAccordion>

      {/* Price */}
      <AccountCoachAccordion title="Pricing">
        <FormControl margin="normal" fullWidth>
          <FormLabel sx={{ fontSize: "1.2rem" }}>
            Available Training Options
          </FormLabel>

          <Controller
            name="onlineSessions"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label="I offer online training sessions"
              />
            )}
          />

          <Controller
            name="offlineSessions"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label="I offer in-person training sessions"
              />
            )}
          />
        </FormControl>
        {watch("onlineSessions") && (
          <AccountCoachPrice
            watch={watch}
            control={control}
            name="priceListOnline"
            title="Online Training Options"
          />
        )}
        {watch("offlineSessions") && (
          <AccountCoachPrice
            watch={watch}
            control={control}
            name="priceListInPerson"
            title="In-Person Training Options"
          />
        )}
      </AccountCoachAccordion>

      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Button
          type="button"
          variant="contained"
          size="large"
          onClick={handleSubmit(onSubmit)}
          sx={{ p: 2 }}
        >
          Update Profile
        </Button>
      </Box>
    </form>
  );
};
