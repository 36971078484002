import React, { useMemo } from "react";
import { AppBar, Button, Container, Toolbar } from "@mui/material";
import { useSignOut } from "react-auth-kit";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { getUserMenuItems, menuItems, ROUTS } from "../../constants/config";
import { HeaderDesktopMenu } from "./HeaderDesktopMenu";
import { HeaderMobileMenu } from "./HeaderMobileMenu";
import { HeaderUserMenu } from "./HeaderUserMenu";
import { HeaderLogo } from "./HeaderLogo";
import { useCurrentUser } from "../../hooks/useCurrentUser";

export type NavItem = {
  text: string;
  to: string;
};

export function Header() {
  const user = useCurrentUser();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate(ROUTS.SIGN_IN);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userMenuItems = useMemo(
    () => getUserMenuItems(user?.userRole === "coach"),
    [user],
  );

  return (
    <AppBar position="static" color="secondary" sx={{ boxShadow: "none" }}>
      <Container maxWidth="md">
        <Toolbar disableGutters sx={{ py: 2, gap: 2 }}>
          <HeaderLogo type="desktop" />

          <HeaderMobileMenu
            menuItems={menuItems}
            anchorEl={anchorElNav}
            onOpen={handleOpenNavMenu}
            onClose={handleCloseNavMenu}
          />

          <HeaderLogo type="mobile" />

          <HeaderDesktopMenu
            menuItems={menuItems}
            onClose={handleCloseNavMenu}
          />

          {user ? (
            <HeaderUserMenu
              menuItems={userMenuItems}
              anchorEl={anchorElUser}
              onOpen={handleOpenUserMenu}
              onClose={handleCloseUserMenu}
              onSignOut={handleSignOut}
              username={user.username}
            />
          ) : (
            <Button
              variant="contained"
              component={RouterLink}
              to={ROUTS.SIGN_IN}
            >
              Log in
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
