import { useApiRequest } from "../../common/hooks/useApiRequest";
import { API_ENDPOINTS } from "../../common/constants/config";

const params = {
  populate: [
    "image",
    "details",
    "coaches",
    "service",
    "service.items",
    "coach",
    "coach.image",
    "coaches.avatar",
    "coaches.sportType",
    "details.image",
    "details.items",
    "details.items.image",
  ],
  publicationState: "live",
};

export const useHomeData = () => {
  const { data, loaded, error } = useApiRequest({
    url: API_ENDPOINTS.HOME,
    method: "GET",
    params,
  });

  return {
    data,
    loaded,
    error,
  };
};
