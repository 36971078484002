import { BookFormData, Coach, Id, PriceListItem } from "../../typings";
import { ROUTS, testCall } from "../../common/constants/config";

export const findSessionWithLowestPrice = (sessions: PriceListItem[]) => {
  return sessions?.reduce((prev, current) => {
    return prev.price < current.price ? prev : current;
  }, {} as PriceListItem);
};

export const prepareData = (
  coachId: Id,
  traineeId: Id,
  priceId: Id,
  data: BookFormData,
  coach: Coach,
) => {
  const priceListOnline = coach?.priceListOnline || [];
  const priceListInPerson = coach?.priceListInPerson || [];

  const isOffline = priceListInPerson.some(
    (priceListItem: PriceListItem) => String(priceListItem.id) === priceId,
  );

  const price =
    [...priceListOnline, ...priceListInPerson].find(
      (priceListItem: PriceListItem) => String(priceListItem.id) === priceId,
    ) || testCall;

  return {
    ...data,
    priceId,
    coachId,
    traineeId,
    training: price?.title,
    trainingPrice: price?.price,
    duration: price?.duration,
    description: price?.description,
    weekDays: data.weekDays?.join(),
    time: data.time?.join(),
    coach: coachId,
    isOnline: !isOffline,
  };
};

export const getBreadcrumbs = (
  coachId: Id,
  data: BookFormData,
): { to?: string; text: string }[] => {
  return [
    { to: ROUTS.HOME, text: "Home" },
    { to: ROUTS.COACHES, text: "Coaches" },
    { to: `${ROUTS.COACH}/${coachId}`, text: String(data?.fullName) },
    { text: "Book" },
  ];
};
