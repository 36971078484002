import React from "react";
import { useParams } from "react-router-dom";

import { CoachDetails } from "./CoachDetails";
import { PageContainer } from "../../common/components/PageContainer/PageContainer";
import { useCoachData } from "../../common/hooks/useCoachData";
import { useTitle } from "react-use";

export function CoachDetailsContainer() {
  const { coachId } = useParams();
  const { data, loaded, error } = useCoachData(String(coachId));
  useTitle(data?.fullName);

  return (
    <PageContainer loading={!loaded} error={Boolean(error)}>
      {loaded && <CoachDetails coach={data} />}
    </PageContainer>
  );
}
