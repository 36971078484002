import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { ROUTS, UPLOAD_URL } from "../../common/constants/config";
import { HomeData } from "../../typings";

type Props = {
  data: HomeData;
};

const textShadow = "2px 3px 6px #777465BD";

export function HomeHeadline({ data }: Props) {
  const { title, subtitle, image } = data || {};

  const [cachedImage, setCachedImage] = useState<string | null>(null);

  useEffect(() => {
    if (image?.url) {
      const cachedUrl = localStorage.getItem(image.url);
      if (cachedUrl) {
        setCachedImage(cachedUrl);
      } else {
        const img = new Image();
        img.src = image.url;
        img.onload = () => {
          localStorage.setItem(image.url, image.url);
          setCachedImage(image.url);
        };
      }
    }
  }, [image]);

  if (!data) return null;

  return (
    <Box
      sx={{
        backgroundImage: `url(${cachedImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "primary.main",
        minHeight: { sm: "calc(100vh - 90px)", xs: "calc(100vh - 82px)" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="common.white"
        textTransform="uppercase"
        sx={{
          textShadow,
          fontWeight: 600,
          fontSize: { xs: "1.5rem", md: "3rem" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="common.white"
        textTransform="uppercase"
        sx={{
          textShadow,
          fontWeight: 600,
          fontSize: { xs: "1rem", md: "2rem" },
          mb: 3,
        }}
      >
        {subtitle}
      </Typography>
      <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
        <Button
          variant="contained"
          size="large"
          component={RouterLink}
          to={ROUTS.COACHES}
          sx={{
            px: 4,
            py: 2,
            fontSize: { xs: "1rem", md: "1.5rem" },
            textTransform: "none",
            borderRadius: 2,
          }}
        >
          Find your coach
        </Button>
      </Stack>
    </Box>
  );
}
