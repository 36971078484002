import { useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";

import { fetchOrders } from "../utils/fetchOrders";
import { Order } from "../../typings";

export const useFetchOrders = (type: string) => {
  const authHeader = useAuthHeader();

  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchOrders(authHeader(), type);
        const orders = response.data.data;

        setOrders(orders);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  return { orders, isLoading, error };
};
