import React from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import {
  TextField,
  Button,
  Link,
  Box,
  Typography,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { SignUpFormData } from "./types";

import { ROUTS } from "../../common/constants/config";

type Props = {
  onSignUp: SubmitHandler<SignUpFormData>;
};

export function SignUpForm({ onSignUp }: Props) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<SignUpFormData>();

  const onSubmit: SubmitHandler<SignUpFormData> = (data) => {
    onSignUp(data);
  };

  const isDisabled =
    !watch("fullName") ||
    !watch("phoneNumber") ||
    !watch("email") ||
    !watch("acceptTerms") ||
    !watch("password");

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="fullName"
            label="Full Name"
            autoFocus
            {...register("fullName")}
          />
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <MuiTelInput
                onChange={onChange}
                value={value}
                label="Phone Number"
                margin="normal"
                fullWidth
                autoComplete="tel"
              />
            )}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            error={!!errors.email} // Отображение ошибки, если есть
            helperText={errors.email ? "Invalid email address" : ""} // Сообщение об ошибке
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address",
              },
            })}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            id="password"
            {...register("password")}
          />
          <FormControl component="fieldset" margin="normal">
            <Controller
              name="userRole"
              control={control}
              defaultValue="trainee"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="trainee"
                    control={<Radio />}
                    label="I'm a trainee"
                  />
                  <FormControlLabel
                    value="coach"
                    control={<Radio />}
                    label="I'm a coach"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isDisabled}
          >
            Sign Up
          </Button>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox {...register("acceptTerms", { required: true })} />
              }
              label={
                <React.Fragment>
                  I accept the{" "}
                  <Link component={RouterLink} to={ROUTS.TERMS_OF_USE}>
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link component={RouterLink} to={ROUTS.PRIVACY_POLICY}>
                    Privacy Policy
                  </Link>
                </React.Fragment>
              }
            />
          </FormGroup>
        </Box>
      </Box>
    </Container>
  );
}
