export const sessionTypeOutput = (
  online?: boolean,
  offline?: boolean,
): string => {
  if (online && offline) {
    return "Online and offline";
  }

  if (online) {
    return "Online";
  }

  return "Offline";
};
