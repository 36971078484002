import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";

import { PageContainer } from "../../../common/components/PageContainer/PageContainer";
import { AccountCoachForm } from "./AccountCoachForm";
import { AccountCard } from "../AccountCard";
import { useFetchAccount } from "../../../common/hooks/useFetchAccount";
import { UserProfileFormData } from "./typings";
import { usePutCoachAccount } from "../../../common/hooks/usePutCoachAccount";
import { usePostCoachAccount } from "../../../common/hooks/usePostCoachAccount";
import { useUploadMedia } from "../../../common/hooks/useUploadMedia";
import { AccountGallery } from "./AccountGallery";
import { Media } from "../../../typings";
import { AccountAvatar } from "./AccountAvatar";

const transformToFormValues = ({ coach, user }: any) => {
  return {
    fullName: coach?.fullName || user?.username || "",
    email: coach?.email || user?.email || "",
    phoneNumber: coach?.phone || user?.phoneNumber || "",
    sportType: coach?.tempSportType ? "Other" : coach?.sportType?.id ?? "",
    tempSportType: coach?.tempSportType || "",
    experience: coach?.experience || 0,
    about: coach?.about || "",
    languages: coach?.languages?.map((lang: any) => lang?.id) ?? [],
    children: coach?.children || false,
    adults: coach?.adults || false,
    onlineSessions: coach?.onlineSessions || false,
    offlineSessions: coach?.offlineSessions || false,
    certificates: coach?.certificates || "",
    geo: { locality: coach?.geoLocality, country: coach?.geoCountry },
    priceListInPerson: coach?.priceListInPerson || [],
    priceListOnline: coach?.priceListOnline || [],
  };
};

const transformUserToCoach = (user: any) => ({
  fullName: user?.fullName || "",
  email: user?.email || "",
  phone: user?.phoneNumber || "",
  user: user?.id,
});

export const AccountCoachContainer = () => {
  const {
    user,
    sportTypes,
    languages,
    fetchAccount,
    isLoading: fetchAccountLoading,
  } = useFetchAccount();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [gallery, setGallery] = useState<{ files: File[]; media: Media[] }>({
    files: [],
    media: [],
  });
  const { createAccountCoach, isLoading: postAccountLoading } =
    usePostCoachAccount();
  const { updateAccountCoach, isLoading: putAccountLoading } =
    usePutCoachAccount(user?.coach?.id);
  const { uploadMedia, isLoading: uploadLoading } = useUploadMedia();

  const isLoading =
    fetchAccountLoading ||
    postAccountLoading ||
    putAccountLoading ||
    uploadLoading;

  const coach = user?.coach;
  const initialValues = useMemo(
    () => transformToFormValues({ coach, user }),
    [coach, user],
  );

  useEffect(() => {
    if (!coach && user) {
      (async () => {
        await createAccountCoach(transformUserToCoach(user));
        await fetchAccount();
      })();
    }
  }, [user?.id]);

  const handleSubmit = async (data: UserProfileFormData) => {
    const avatarId = avatar
      ? (await uploadMedia([avatar], `coach_${coach?.id}_avatar`))?.[0]?.id
      : null;
    const galleryNewIds = gallery.files.length
      ? (await uploadMedia(gallery.files, `coach_${coach?.id}_gallery`))?.map(
          (image: Media) => image.id,
        )
      : [];

    const galleryIds = [
      ...gallery.media.map((image: Media) => image.id),
      ...galleryNewIds,
    ];
    const galleryData = { gallery: galleryIds.length ? galleryIds : undefined };
    const processPriceListItems = async (items: any[]) => {
      return Promise.all(
        items.map(async (item) => {
          if (item.program instanceof File) {
            const uploadedMediaId = (
              await uploadMedia([item.program], `coach_${coach?.id}_program`)
            )?.[0]?.id;
            return { ...item, program: uploadedMediaId };
          }
          return item;
        }),
      );
    };

    const priceListInPerson = data.priceListInPerson
      ? await processPriceListItems(data.priceListInPerson)
      : coach?.priceListInPerson;
    const priceListOnline = data.priceListOnline
      ? await processPriceListItems(data.priceListOnline)
      : coach?.priceListOnline;

    const sportTypeData =
      data.sportType === "Other"
        ? { sportType: null, tempSportType: data.tempSportType }
        : { sportType: data.sportType || coach?.sportType, tempSportType: "" };

    await updateAccountCoach({
      ...galleryData,
      ...sportTypeData,
      avatar: avatarId || coach?.avatar?.id,
      languages: data.languages || coach?.languages,
      fullName: data.fullName,
      phone: data.phoneNumber,
      experience: data.experience,
      about: data.about,
      certificates: data.certificates,
      children: data.children,
      adults: data.adults,
      onlineSessions: data.onlineSessions,
      offlineSessions: data.offlineSessions,
      geoLocality: data.geo?.locality,
      geoCountry: data.geo?.country,
      priceListInPerson,
      priceListOnline,
    });
  };

  return (
    <PageContainer loading={isLoading}>
      {user && coach && (
        <>
          <Typography
            component="h1"
            variant="h5"
            sx={{ textAlign: "center", mb: 5 }}
          >
            My Account
          </Typography>
          <AccountCard>
            <AccountCoachForm
              onSubmit={handleSubmit}
              initialValues={initialValues}
              sportTypeOptions={sportTypes}
              languageOptions={languages}
              avatar={
                <AccountAvatar
                  initialImage={coach.avatar}
                  onChange={setAvatar}
                />
              }
              gallery={
                <AccountGallery
                  initialImages={coach.gallery}
                  onChange={setGallery}
                />
              }
            />
          </AccountCard>
        </>
      )}
    </PageContainer>
  );
};
