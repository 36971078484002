import React, { useMemo } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";

import { Geo, SportType } from "../../typings";
import { GeoAutocomplete } from "./GeoAutocomplete";

type Props = {
  sportTypes?: SportType[];
  onSportTypeChange: (sportType?: SportType) => void;
  onGeoChange: (geo: Geo | null) => void;
};

type SportOption = {
  label: string;
  value: SportType;
};

export const CoachFilters = ({
  sportTypes,
  onSportTypeChange,
  onGeoChange,
}: Props) => {
  const sportTypeOptions = useMemo(() => {
    return (sportTypes || []).map((sportType) => ({
      label: sportType.name,
      value: sportType,
    }));
  }, [sportTypes]);

  return (
    <Grid container spacing={4} sx={{ py: 3 }}>
      <Grid item xs={12} md={6}>
        <Autocomplete<SportOption>
          isOptionEqualToValue={(option, value) =>
            option.value.id === value.value.id
          }
          disablePortal
          onChange={(_, selected) => {
            onSportTypeChange(selected?.value);
          }}
          options={sportTypeOptions}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Enter sport type" margin="normal" />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeoAutocomplete onChange={onGeoChange} />
      </Grid>
    </Grid>
  );
};
