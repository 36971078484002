import React from "react";
import { Typography } from "@mui/material";

import { AuthUser } from "../../../typings";
import { PageContainer } from "../../../common/components/PageContainer/PageContainer";
import { AccountItem } from "../AccountItem";
import { AccountCard } from "../AccountCard";

type Props = {
  user: AuthUser;
};

export const AccountTrainee = ({ user }: Props) => {
  return (
    <PageContainer>
      <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
        My Account
      </Typography>
      <AccountCard title="Contact Information">
        <AccountItem label="Full Name" value={user.fullName} />

        <AccountItem label="Email" value={user.email} />

        <AccountItem label="Phone Number" value={user.phoneNumber} />
      </AccountCard>
    </PageContainer>
  );
};
