import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { SignInFormData } from "./types";
import { API_ENDPOINTS, API_URL } from "../../common/constants/config";
import { AuthResponse } from "../../typings";
import { useAuthSignIn } from "../../common/hooks/useAuthSignIn";

interface UseSignInResult {
  signIn: (data: SignInFormData) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
}

const toSignInData = (data: SignInFormData) => {
  return {
    identifier: data.identifier,
    password: data.password,
  };
};
export const useSignIn = (redirect: string): UseSignInResult => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();
  const authSignIn = useAuthSignIn();

  const signIn = async (data: SignInFormData) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post<AuthResponse>(
        `${API_URL}${API_ENDPOINTS.SIGN_IN}`,
        toSignInData(data),
      );

      authSignIn(response.data);

      navigate(redirect);
    } catch (err: any) {
      setError(err);
      console.error("Log in error:", err);
    } finally {
      setLoading(false);
    }
  };

  return { signIn, isLoading, error };
};
