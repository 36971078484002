import axios from "axios";
import { AuthUser } from "../../typings";
import { API_ENDPOINTS, API_URL } from "../constants/config";

export const fetchCurrentUser = (authHeader: string) =>
  axios.get<AuthUser>(`${API_URL}${API_ENDPOINTS.ME}`, {
    headers: {
      Authorization: authHeader,
    },
    params: {
      populate: [
        "coach",
        "coach.avatar",
        "coach.sportType",
        "coach.languages",
        "coach.gallery",
        "coach.priceList",
        "coach.tempSportType",
        "coach.tempSportType",
        "coach.priceListOnline",
        "coach.priceListOnline.program",
        "coach.priceListInPerson",
        "coach.priceListInPerson.program",
      ],
    },
  });
