import {
  AuthUser,
  CoachProfile,
  Language,
  Media,
  Option,
  SportType,
} from "../../typings";

export const transformMedia = (media: any): Media => {
  return {
    id: media?.id,
    alternativeText: media?.alternativeText,
    caption: media?.caption,
    width: media?.width,
    height: media?.height,
    formats: media?.formats && {
      thumbnail: {
        width: media?.formats?.thumbnail?.width,
        height: media?.formats?.thumbnail?.height,
        size: media?.formats?.thumbnail?.size,
        url: media?.formats?.thumbnail?.url,
      },
      small: {
        width: media?.formats?.small?.width,
        height: media?.formats?.small?.height,
        size: media?.formats?.small?.size,
        url: media?.formats?.small?.url,
      },
      medium: {
        width: media?.formats?.medium?.width,
        height: media?.formats?.medium?.height,
        size: media?.formats?.medium?.size,
        url: media?.formats?.medium?.url,
      },
      large: {
        width: media?.formats?.large?.width,
        height: media?.formats?.large?.height,
        size: media?.formats?.large?.size,
        url: media?.formats?.large?.url,
      },
    },
    url: media?.url,
  };
};

const transformCoach = (coach: any): CoachProfile => {
  return {
    id: coach?.id,
    fullName: coach?.fullName,
    phone: coach?.phone,
    email: coach?.email,
    publishedAt: coach?.publishedAt,
    experience: coach?.experience,
    children: coach?.children,
    adults: coach?.adults,
    about: coach?.about,
    geoLocality: coach?.geoLocality,
    geoCountry: coach?.geoCountry,
    onlineSessions: coach?.onlineSessions,
    offlineSessions: coach?.offlineSessions,
    gallery: coach?.gallery?.map(transformMedia),
    avatar: transformMedia(coach?.avatar),
    sportType: transformSportType(coach?.sportType),
    tempSportType: coach?.tempSportType,
    languages: coach?.languages?.map(transformLanguage),
    certificates: coach?.certificates,
    priceListInPerson: coach?.priceListInPerson,
    priceListOnline: coach?.priceListOnline,
  };
};

export const transformLanguage = (language: any): Language => {
  return {
    id: language?.id,
    name: language?.name,
    code: language?.code,
  };
};

export const transformSportType = (sportType: any): SportType => {
  return {
    id: sportType?.id,
    name: sportType?.name,
  };
};

export const transformToSportTypes = (response: any): SportType[] => {
  return response?.map((item: any) => ({
    id: item?.id,
    name: item?.attributes?.name,
  }));
};

export const transformToOptions = (response: any): Option[] => {
  return response?.map((item: any) => ({
    label: item?.attributes?.name,
    value: item?.id,
  }));
};

export const transformCoachProfile = (data: any): AuthUser => {
  const coach = data?.coach ? transformCoach(data?.coach) : null;

  return {
    id: data?.id,
    username: data?.username,
    fullName: data?.fullName,
    email: data?.email,
    provider: data?.provider,
    confirmed: data?.confirmed,
    blocked: data?.blocked,
    phoneNumber: data?.phoneNumber,
    userRole: data?.userRole,
    coach: coach,
  };
};
