import React from "react";
import { Card, Link, Typography } from "@mui/material";

import { Order } from "../../typings";
import { formatMoney } from "../../common/helpers/formatMoney";

type Props = {
  orders: Order[];
  title: string;
  empty: string;
};

export const BookedSessions = ({ orders, title, empty }: Props) => {
  if (orders.length < 1) {
    return (
      <>
        <Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: "center", mb: 5 }}
        >
          {title}
        </Typography>
        <Typography>{empty}</Typography>
      </>
    );
  }
  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        sx={{ textAlign: "center", mb: 5 }}
      >
        {title}
      </Typography>
      {orders.map(
        ({
          isOnline,
          fullName,
          email,
          id,
          phoneNumber,
          description,
          duration,
          training,
          trainingPrice,
          time,
          weekDays,
          age,
          zoomLink,
        }) => {
          return (
            <Card
              key={id}
              sx={{
                backgroundColor: "secondary.main",
                boxShadow: "none",
                p: 4,
                my: 2,
                borderRadius: 8,
              }}
            >
              <Typography component="div" variant="h5">
                Order #{id}
              </Typography>
              <Typography component="div" variant="h6" sx={{ mt: 2 }}>
                Contacts
              </Typography>
              <Typography>Name: {fullName}</Typography>
              <Typography>Email: {email}</Typography>
              <Typography>Phone number: {phoneNumber}</Typography>

              <Typography component="div" variant="h6" sx={{ mt: 2 }}>
                Preferred training sessions
              </Typography>
              <Typography>Week days: {weekDays || "-"}</Typography>
              <Typography>Time slots: {time || "-"}</Typography>
              <Typography>Age: {age || "-"}</Typography>

              <Typography component="div" variant="h6" sx={{ mt: 2 }}>
                {training}
              </Typography>
              <Typography>{description}</Typography>
              {zoomLink && (
                <Typography>
                  Meeting link:{" "}
                  <Link href={zoomLink} target="_blank">
                    {zoomLink}
                  </Link>
                </Typography>
              )}
              <Typography>
                Format: {isOnline ? "Online" : "In person (offline)"}
              </Typography>
              <Typography>Duration: {duration} min</Typography>
              <Typography>Price: {formatMoney(trainingPrice)}</Typography>
            </Card>
          );
        },
      )}
    </>
  );
};
