import React from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { PageContainerError } from "./PageContainerError";

type Props = {
  children?: React.ReactNode;
  headline?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
};

const containerStyles = {
  py: 5,
  minHeight: "calc(100vh - 280px)",
  position: "relative" as const,
};

export function PageContainer({ children, loading, error, headline }: Props) {
  if (error)
    return (
      <Container maxWidth="md" sx={containerStyles}>
        <PageContainerError />
      </Container>
    );

  return (
    <>
      {headline}

      <Container maxWidth="md" sx={containerStyles}>
        {children}
      </Container>

      <Backdrop
        sx={{ zIndex: 2, color: "primary.main" }}
        open={Boolean(loading)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
