import React from "react";
import { Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import PlaceIcon from "@mui/icons-material/Place";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

import { Coach } from "../../../typings";
import { ageOutput } from "../../helpers/ageOutput";
import { sessionTypeOutput } from "../../helpers/sessionTypeOutput";

type Props = { coach: Coach };

export const CoachSummary = ({ coach }: Props) => {
  return (
    <>
      <Typography component="div" variant="h5">
        {coach.fullName}
      </Typography>

      <Typography variant="subtitle1" color="text.secondary" component="div">
        {coach.sportType?.name} coach
      </Typography>

      <Typography variant="body2">
        <PlaceIcon fontSize="inherit" sx={{ mr: 1 }} color="primary" />
        {coach.geoCountry}, {coach.geoLocality}
      </Typography>

      <Typography variant="body2">
        <FitnessCenterIcon fontSize="inherit" sx={{ mr: 1 }} color="primary" />
        {sessionTypeOutput(coach.onlineSessions, coach.offlineSessions)}{" "}
        trainings
      </Typography>

      <Typography variant="body2">
        <EscalatorWarningIcon
          fontSize="inherit"
          sx={{ mr: 1 }}
          color="primary"
        />
        {ageOutput(coach.children, coach.adults)}
      </Typography>

      <Typography variant="body2" gutterBottom>
        <LanguageIcon fontSize="inherit" sx={{ mr: 1 }} color="primary" />
        {coach.languages.map(({ name }) => name).join(", ")}
      </Typography>

      <Typography variant="body2">
        Experience: {coach.experience} years
      </Typography>
    </>
  );
};
