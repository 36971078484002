import { useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";

import { AuthUser, Option } from "../../typings";
import { fetchCurrentUser } from "../utils/fetchCurrentUser";
import { transformCoachProfile, transformToOptions } from "../utils/transform";
import { fetchLanguages } from "../utils/fetchLanguages";
import { fetchSportTypes } from "../utils/fetchSportTypes";

export const useFetchAccount = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sportTypes, setSportTypes] = useState<Option[]>([]);
  const [languages, setLanguages] = useState<Option[]>([]);
  const [user, setUser] = useState<AuthUser | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const authHeader = useAuthHeader();
  const fetchAccount = async () => {
    try {
      setLoading(true);
      const [userResponse, languagesResponse, sportTypesResponse] =
        await Promise.all([
          fetchCurrentUser(authHeader()),
          fetchLanguages(),
          fetchSportTypes(),
        ]);

      setSportTypes(transformToOptions(sportTypesResponse.data.data));
      setLanguages(transformToOptions(languagesResponse.data.data));
      setUser(transformCoachProfile(userResponse.data));
    } catch (err: any) {
      setError(err);
      console.error("Error fetching coach account data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return { fetchAccount, user, sportTypes, languages, isLoading, error };
};
