import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import { Coach } from "../../typings";
import { testCall, UPLOAD_URL } from "../../common/constants/config";
import { CoachSummary } from "../../common/components/CoachSummary/CoachSummary";
import { CoachGallery } from "./CoachGallery";
import defaultAvatar from "../../common/images/default-avatar.svg";
import { CoachPriceSelect } from "./CoachPriceSelect";

type Props = { coach: Coach };

export const CoachDetails = ({ coach }: Props) => {
  const {
    fullName,
    avatar,
    id,
    gallery,
    priceListOnline = [],
    priceListInPerson = [],
    onlineSessions,
    offlineSessions,
  } = coach;

  return (
    <>
      <Card
        sx={{
          backgroundColor: "secondary.main",
          boxShadow: "none",
          p: 2,
          borderRadius: 8,
        }}
      >
        <Grid container sx={{ py: 2 }}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Box sx={{ p: 2 }}>
              <Avatar
                alt={fullName}
                src={
                  avatar?.url ? `${UPLOAD_URL}${avatar?.url}` : defaultAvatar
                }
                sx={{ width: 120, height: 120 }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <CoachSummary coach={coach} />
              </CardContent>
            </Box>
          </Grid>

          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {coach.about}
              </Typography>
              {coach.certificates && (
                <>
                  <Typography variant="body1">
                    <strong>Certificates:</strong> {coach.certificates}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {gallery && <CoachGallery gallery={gallery} />}

      <Grid container spacing={2} sx={{ py: 4 }}>
        <CoachPriceSelect
          coachId={id}
          title="Free"
          priceList={[testCall]}
          fullWidth={onlineSessions && offlineSessions}
        />
        {onlineSessions && (
          <CoachPriceSelect
            coachId={id}
            title="Online Training"
            priceList={priceListOnline}
          />
        )}
        {offlineSessions && (
          <CoachPriceSelect
            coachId={id}
            title="In-Person Training"
            priceList={priceListInPerson}
          />
        )}
      </Grid>
    </>
  );
};
