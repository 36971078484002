import React, { useMemo } from "react";
import { Coach } from "../../typings";
import { ROUTS, UPLOAD_URL } from "../../common/constants/config";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { CoachSummary } from "../../common/components/CoachSummary/CoachSummary";
import { formatMoney } from "../../common/helpers/formatMoney";
import defaultAvatar from "../../common/images/default-avatar.svg";
import { findSessionWithLowestPrice } from "../Book/helpers";

type Props = { coach: Coach };

export const CoachItem = ({ coach }: Props) => {
  const {
    fullName,
    avatar,
    id,
    priceListInPerson = [],
    priceListOnline = [],
    onlineSessions,
    offlineSessions,
  } = coach;

  const lowestPrice = useMemo(() => {
    return findSessionWithLowestPrice([
      ...(onlineSessions ? priceListOnline : []),
      ...(offlineSessions ? priceListInPerson : []),
    ]);
  }, [priceListInPerson, priceListOnline, onlineSessions, offlineSessions]);

  return (
    <Grid item key={id} xs={12} md={6}>
      <Card
        sx={{
          backgroundColor: "secondary.main",
          boxShadow: "none",
          p: 2,
          borderRadius: 8,
        }}
      >
        <Box sx={{ display: { sm: "flex" } }}>
          <Box sx={{ p: 2 }}>
            <Avatar
              alt={fullName}
              src={`${UPLOAD_URL}${avatar?.url}` || defaultAvatar}
              sx={{ width: 120, height: 120 }}
            />
          </Box>

          <CardContent sx={{ flex: "1 0 auto" }}>
            <CoachSummary coach={coach} />
          </CardContent>
        </Box>

        <Divider />

        <CardActions sx={{ justifyContent: "space-between", gap: 2, p: 2 }}>
          {lowestPrice?.price && (
            <Box sx={{ minWidth: 0 }}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                noWrap
                title={lowestPrice?.title || ""}
              >
                {lowestPrice?.title || ""}
              </Typography>
              <Typography variant="subtitle1" color="primary" component="div">
                {formatMoney(lowestPrice?.price || 0)}
              </Typography>
            </Box>
          )}

          <Button
            key={id}
            component={RouterLink}
            to={`${ROUTS.COACH}/${id}`}
            sx={{ px: 4, minWidth: 0 }}
            variant="contained"
          >
            Book
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
